import React, { useState, useEffect, useRef, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";
import Footer from "../components/Footer";
import { registrarUsuario } from "../repository";
import ClientCaptcha from "react-client-captcha";
import Header from "../components/Header";

const Register = () => {
  const [nombre, setNombre] = useState();
  const [eMail, setEmail] = useState("");
  const [contrasenia] = useState("");
  const [loading, setloading] = useState(0);
  const [captchaCode, setCaptchaCode] = useState("");
  const [captchaCapturado, setCaptchaCapturado] = useState("");
  const [catpchaCorrect, setCaptchaCorrect] = useState(false);
  const [, setForceUpdate] = useState(0);

  const submitLogin = (event) => {
    event.preventDefault();
    setForceUpdate(1);
    setloading(1);
    if (validator.current.allValid() && catpchaCorrect) {
      registrarUsuario(nombre, eMail)
        .then((response) => {
          if (
            response.statusText === "OK" &&
            response.data === "El correo proporcionado ya existe."
          ) {
            Swal(
              "Aviso",
              "Este correo ya ha sido registrado previamente. Favor de iniciar sesión.",
              "info"
            ).then(() => {
              window.location = "Login";
            });
          } else {
            window.location = "Home";
          }
          setloading(0);
        })
        .catch((err) => {
          Swal("Error de Conexón al Sistema", err, "error");
          setloading(0);
        });
    } else {
      setloading(0);
      Swal("Aviso", "Favor de comprobar la información ingresada.", "info");
      validator.current.showMessages();
    }
  };

  useEffect(() => {
    validator.current.hideMessages();
  }, []);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    })
  );

  return (
    <div>
      <Header
        title="Registro"
        class1="row py-1 bg-warning text-black"
        class2="col-md-6"
        class3="fa fa-user-plus"
      />

      {/* REGISTRO */}
      <section id="login">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto mt-5">
              <div className="card">

              <div className="card-header text-center" >
              <h3> Registro
              </h3>
              </div>
                <div className="card-body">
                  <form onSubmit={submitLogin}>
                    <div className="form-group">
                      <label htmlFor="usuario">Nombre</label>
                      <input
                        type="text"
                        name="nombre"
                        className="form-control"
                        onChange={(e) => setNombre(e.target.value)}
                        defaultValue={nombre}
                        maxLength="50"
                        autoComplete="off"
                      />
                      {validator.current.message(
                        "nombre",
                        nombre,
                        "required|string"
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Email</label>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        onChange={(e) => setEmail(e.target.value)}
                        defaultValue={contrasenia}
                        maxLength="50"
                      />
                      {validator.current.message(
                        "contrasenia",
                        eMail,
                        "required|email"
                      )}
                    </div>
                    <div className="form-group">
                      <ClientCaptcha
                        captchaCode={(code) => {
                          setCaptchaCode(code);
                          setCaptchaCorrect(false);
                        }}
                      />
                      Capture el código mostrado.
                      <input
                        type="text"
                        name="captcha"
                        className={
                          catpchaCorrect
                            ? "form-control mt-3 text-success"
                            : "form-control mt-3 text-danger"
                        }
                        onChange={(e) => {
                          setCaptchaCapturado(e.target.value);
                          if (e.target.value === captchaCode) {
                            setCaptchaCorrect(true);
                          } else {
                            setCaptchaCorrect(false);
                          }
                        }}
                        defaultValue={captchaCapturado}
                        maxLength="50"
                      />
                      {validator.current.message(
                        "captcha",
                        captchaCapturado,
                        "required|string"
                      )}
                    </div>

                    {loading === 0 && (
                      <Fragment>
                        <div className="row">
                          <div className="col-md">
                            <button
                              value="Aceptar"
                              className="btn btn-warning btn-block mt-3"
                              onClick={(e) => {
                                submitLogin(e);
                              }}
                            >
                              Aceptar
                            </button>
                          </div>
                          <div className="col-md">
                            <button
                              className="btn btn-secondary btn-block mt-3"
                              onClick={(e) => {
                                e.preventDefault();
                                window.location = "/search";
                              }}
                            >
                              Cancelar
                            </button>
                          </div>
                        </div>
                      </Fragment>
                    )}

                    {loading === 1 && (
                      <div className="text-center mt-3">
                        <h6>Cargando...</h6>
                        <div
                          className="spinner-border text-warning"
                          role="status"
                          aria-hidden="true"
                        ></div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Register;
