import React, { Fragment, useEffect, useState, useMemo } from "react";
import Swal from "sweetalert";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { getPedidosAdmon } from "../repository";
import TableAdmon from "../components/TableAdmon";

const Admon = () => {
  const [pedidos, setPedidos] = useState({});
  const [status, setStatus] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "PEDIDOS GENERADOS",
        columns: [
          {
            Header: "#",
            accessor: "idPedido",
          },
          {
            Header: "idCliente",
            accessor: "idClienteWeb",
          },
          {
            Header: "Cliente",
            accessor: "Cliente",
          },
          {
            Header: "Fecha",
            accessor: "Fecha",
          },
          {
            Header: "Total",
            accessor: "Total",
          },

          {
            Header: "Estado",
            accessor: "Estatus",
          },
          {
            Header: "Guía",
            accessor: "NumeroGuia",
          },
          {
            Header: "",
            accessor: "boton",
          },
        ],
      },
    ],
    []
  );

  const obtienePedidos = () => {
    var user = JSON.parse(sessionStorage.getItem("usuario"));
    if (user !== null) {
      getPedidosAdmon()
        .then((response) => {
          if (response.status === "success") {
            setStatus("success");
            setPedidos(response.result);
          } else {
            Swal(
              "Error obtener la información [Pedidos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) =>
          Swal("Error obtener la información [Pedidos]", err, "error")
        );
    } else {
      setStatus("success");
      setPedidos([]);
    }
  };

  useEffect(() => {
    obtienePedidos();
  }, []);

  return (
    <div>
      <Header
        title="Administración"
        class1="row py-1 bg-warning text-black"
        class2="col-md-6"
        class3="fa fa-clipboard-list"
      />
      <section id="posts">
        <div className="container mb-3">
          <div className="row mt-3">
            {status !== "success" && (
              <div className="col-md-12 text-center">
                <h6>Cargando...</h6>
                <div
                  className="spinner-border text-warning"
                  role="status"
                  aria-hidden="true"
                ></div>
              </div>
            )}
            {status === "success" && pedidos.length === 0 && (
              <Fragment>
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <small>
                    <strong>No se encontró información.</strong>
                  </small>
                  <div className="col-md-3"></div>
                </div>
              </Fragment>
            )}

            {status === "success" && pedidos.length > 0 && (
              <div className="table-responsive">
                <TableAdmon columns={columns} data={pedidos} />
              </div>
            )}
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Admon;
