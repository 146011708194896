import NavBar from "./NavBar";
import React, { useEffect } from "react";

const Header = (props) => {
  const navBarRef = React.useRef(NavBar);

  useEffect(() => {
    navBarRef.current.getCartItems();
  }, [props]);

  return (
    <div className="row-negro">
      <div className="container">
        <div className="row">
          <div className="col-xs">
            <div className="row">
              <a href="/">
                <img
                  src={window.location.origin + "/banner/logo_izq.png"}
                  alt=""
                  width={100}
                  height={100}
                />
              </a>
            </div>
          </div>
          <div className="col">
            <div className="row mt-2 justify-content-center ">
             
                <a href="/">
                  <img
                    src={window.location.origin + "/banner/logo_central.PNG"}
                    alt=""
                  
                    width={190}
                    height={90}
                  />
                </a>
              </div>
          
          </div>

          <div className="col-xs">
            <div className="col-flex">
              <div className="row justify-content-end">
                <a href="/">
                  <img
                    src={window.location.origin + "/banner/logo_der.png"}
                    alt=""
                    width={150}
                    height={100}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="row mt-3 mb-2 content-align-center">
              <div className="col ">
                <NavBar ref={navBarRef}></NavBar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
