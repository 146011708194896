import React, { Fragment, useState, useRef, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Redirect } from "react-router-dom";
import Swal from "sweetalert";
import Swal2 from "sweetalert2";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CartItem from "../components/CartItem";
import {
  numberFormat,
  agregaPedidos,
  agregaDetallePedidos,
  getDatosFacturacion,
  getNotificacion,
} from "../repository";
import Global from "../components/Global";

const Cart = () => {

  const [status, setStatus] = useState(null);
  const [productos, setProductos] = useState({});
  const [subtotal, setSubtotal] = useState(0);
  const [items, setItems] = useState(0);
  const [orderStatus, setOrderStatus] = useState("");
  const [tipoPago, setTipoPago] = useState(0);
  const [efectivoChecked, setEfectivoChecked] = useState(false);
  const [bancarioChecked, setbancarioChecked] = useState(false);
  useEffect(() => {
    if (orderStatus === "success") {
      sessionStorage.removeItem("cart");
    } else {
      validator.current.hideMessages();
      getCart();
    }
  }, [orderStatus]);

  const getCart = () => {
    if (sessionStorage.getItem("cart") !== null) {
      var cart = JSON.parse(sessionStorage.getItem("cart"));
      var subtotal = 0;
      var items = 0;
      Object.entries(cart).forEach(([key, item]) => {
        if (item.Inventario >= item.Cantidad) {
          subtotal += item.PrecioPesosMayoreo * item.Cantidad;
          items += item.Cantidad;
        } else {
          subtotal += 0;
          items += 0;
        }
      });
      setStatus(Object.keys(cart).length > 0 ? "success" : "failed");
      setProductos(cart);
      setSubtotal(subtotal);
      setItems(items);
    }
  };

  const seleccionarTipoPago = (e) => {
    if (e.target.name === "rdoEfectivo") {
      setEfectivoChecked(true);
      setbancarioChecked(false);

      mostarTipoPago(1);
      setTipoPago(1);
    }
    if (e.target.name === "rdoBancario") {
      setEfectivoChecked(false);
      setbancarioChecked(true);

      mostarTipoPago(2);
      setTipoPago(2);
    }
  };

  const saveOrder = async (e) => {
    e.preventDefault();
    var user = JSON.parse(sessionStorage.getItem("usuario"));
    if (user === null) {
      Swal2.fire({ title: "Aviso",
        text: "Por favor registrese para poder continuar con su compra",
        icon: "info",
        showCancelButton:false,
        confirmButtonText:"Registrarme",
        confirmButtonColor:"orange",
       
        footer:'Si ya tiene usuario, inicie sesión  <a href="/Login" className="link" ><b> aquí</b> </a> ',
      }).then((result) =>
        {
          if(result.isConfirmed)
            {
                window.location="/Register"
            }
            
        })

   /*   Swal(  {
        title: "Registro",
        text: "Por favor registrese para poder continuar con su compra",
        icon: "info",
        showCancelButton:true,
        confirmButtonText: "usuario nuevo",
      }).then((result) => {
        if (result) {
          window.location = "/Register";
        }
      });
      */
    } else {
      getDatosFacturacion(user[0].idCliente).then((response) => {
        var DatosEnvio = response.result;
        if (DatosEnvio.length === 0) {
          Swal2.fire({
             title:"Envios",
            text:"Por favor actualice su información de envío",
            icon:"info",
            confirmButtonColor:"orange"
            
          }
          ).then((result) => {
            if (response) {
              window.location = "/Shipping";
            }
          });
        } else {
          Swal({
            title: "Envío",
            text: "¿Desea actualizar sus datos de envío?",
            icon: "warning",
            buttons: {
              No: {
                text: "No",
                value: "No",
              },
              Si: {
                text: "Si",
                value: "Si",
              },
            },
          }).then(async (value) => {
            switch (value) {
              case "Si":
                window.location = "/Shipping";
                break;
              case "No":
                if (efectivoChecked !== false || bancarioChecked !== false) {
                  var idClienteWeb = user[0].idCliente;
                  var idClienteGOVI = user[0].idClienteGovi;
                  var idAlmacen = user[0].idAlmacen;
                  //                console.log(idClienteWeb, idClienteGOVI, idAlmacen);
                  var cart = productos;
                  var idPedido = await agregaPedidos(
                    idClienteGOVI,
                    idAlmacen,
                    subtotal +
                      Global.precioEnvio +
                      (subtotal + Global.precioEnvio) * 0.16,
                    "PEDIDO CONTADO: ",
                    idClienteWeb
                  );
                  //console.log(idPedido);
                  if (idPedido > 0) {
                    Object.entries(cart).map(async ([key, item]) => {
                      //Solo los productos que estan disponibles
                      // console.log(item);
                      if (item.Inventario >= item.Cantidad) {
                        await agregaDetallePedidos(
                          idPedido,
                          item.Codigo,
                          item.Descripcion
                            ? item.Descripcion
                            : item.Familia +
                                " / " +
                                item.Marca +
                                " / " +
                                item.TipoVehiculo +
                                " / " +
                                item.Modelo +
                                " / " +
                                item.Posicion +
                                " / " +
                                item.Condicion,
                          item.Cantidad,
                          item.PrecioPesosMayoreo,
                          item.idMarcaProducto,
                          item.idMarcaProducto + " - " + item.MarcaProducto
                        );
                      }
                    });
                    await agregaDetallePedidos(
                      idPedido,
                      "ENVIO",
                      "ENVIO",
                      1,
                      Global.precioEnvio,
                      83,
                      "ENVIO"
                    );
                    Swal2.fire({

                      title: "Pedido #" + idPedido,
                      text:"El Pedido se ha generado correctamente",
                       icon:"success",
                       confirmButtonColor:"orange"
                    }
                    );
                    getNotificacion(idPedido, user[0].idCliente, tipoPago);
                    setStatus("success");
                    setOrderStatus("success");
                  }
                  //console.log("entro a hacer pago");
                } else {
                  Swal2.fire({
                   title: "Metodo de pago",
                    text:"Por favor seleccione un metodo de pago.",
                   icon: "info",
                  confirmButtonColor:"orange"}
                  );
                }
                break;
              default:
                break;
            }
          });
        }
      });
    }
  };

  const mostarTipoPago = (tipoPago) => {
    switch (tipoPago) {
      case 2:
        Swal2.fire({
          title: "PASOS PAGO CON TRANSFERENCIA:",
          icon: "info",
          html:
            '<p align="left"> 1.- Llenar los Datos de Env&iacute;o.' +
            "<br><br>2.- Hacer clic en el bot&oacute;n de Enviar." +
            "<br><br>3.- Acudir al Banco de su preferencia &oacute; entrar al Portal de su Banco y depositar el Total de su Pedido usando la siguiente informaci&oacute;n:" +
            "<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Banco:</b>&nbsp;Banorte</li>" +
            "<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cuenta:</b>&nbsp;1178735583" +
            "<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CLABE:</b>&nbsp;072580011787355832" +
            "<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nombre:</b>&nbsp;GOVI SUSPENSION AUTOMOTRIZ" +
            "<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;RFC:</b>&nbsp;GSA170707LAA </p>" +
            '<p align="left"><br>4.- Confirmar su pago en:' +
            "<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tel&eacute;fono:</b>&nbsp;01(800) 001-4762" +
            "<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WhatsApp:</b>&nbsp;+52 (55) 5031-3427." +
            "</p>",
          showCloseButton: true,
          buttonsStyling: false,
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-warning text-black",
          },
          width: "50rem",
          textalign: "left",
        });
        break;

      case 1:
        Swal2.fire({
          title: "PASOS PAGO EN EFECTIVO:",
          icon: "info",
          html:
            '<p align="left">1.- Llenar los Datos de Env&iacute;o. ' +
            '<br><br>2.- Hacer clic en el bot&oacute;n de "Enviar". ' +
            "<br><br>3.- Acudir a cualquier 7-Eleven y depositar el Total de su Pedido usando la siguiente informaci&oacute;n: " +
            "" +
            "<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Banco:</b>&nbsp;Banorte " +
            "<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cuenta:</b>&nbsp;1178735583 (7-Eleven) " +
            " </p>" +
            '<p align="left"><br>4.- Confirmar su pago en:' +
            "<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tel&eacute;fono:</b>&nbsp;01(800) 001-4762" +
            "<br><b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;WhatsApp:</b>&nbsp;+52 (55) 5031-3427.</p> ",

          showCloseButton: true,
          buttonsStyling: false,
          confirmButtonText: "Aceptar",
          customClass: {
            confirmButton: "btn btn-warning text-black",
          },

          width: "50rem",
        });
        break;
      default:
        break;
    }
  };

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    })
  );

  const removeFromCart = (item) => {
    let cart = productos;
    let idProducto = item.idProducto;
    delete cart[idProducto];
    sessionStorage.setItem("cart", JSON.stringify(cart));
    getCart();
  };

  const updateCart = (item, cant) => {
    let cart = productos;
    let idProducto = item.idProducto;
    cart[idProducto].Cantidad = cant;
    sessionStorage.setItem("cart", JSON.stringify(cart));
    getCart();
  };

  const updateInventarioCart = (item, cant) => {
    let cart = productos;
    let idProducto = item.idProducto;
    cart[idProducto].Inventario = cant;
    sessionStorage.setItem("cart", JSON.stringify(cart));
    getCart();
  };

  if (orderStatus === "success") return <Redirect to="/home" />;
  return (
    <div>
      <Header
        title="Carrito de Compras"
        class1="row py-1 bg-success text-white"
        class2="col-md-6"
        class3="fa fa-shopping-cart"
      />
      {/* CONTENT */}
      <section id="items">
        <div className="container mb-3">
          {status !== "success" && (
            <div className="row mt-3">
              <div className="col-md-3"></div>
              <div className="col-md-6 text-center">
                <small>
                  <strong>El Carrito de Compras está vacío.</strong>
                </small>
                <div className="col-md-3"></div>
              </div>
            </div>
          )}

          {status === "success" && (
            <Fragment>
              <div className="row mt-3">
                <div className="col-md-12">
                  <ul className="list-group">
                    {Object.entries(productos).map(([key, value]) => (
                      <CartItem
                        // ref="cartItemRef"
                        item={value}
                        key={key}
                        remove={removeFromCart}
                        update={updateCart}
                        inventario={updateInventarioCart}
                      />
                    ))}
                  </ul>
                </div>
                <div className="col-md-12 mt-5">
                  <ul className="list-group">
                    <div
                      className="form-check"
                      onChange={(e) => {
                        seleccionarTipoPago(e);
                      }}
                    >
                      <div className="row">
                        <div className="col text-center">
                          <h6>Método de Pago </h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-right">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="rdoEfectivoPago"
                            name="rdoEfectivo"
                            checked={efectivoChecked}
                            defaultValue={efectivoChecked}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rdoEfectivoPago"
                          >
                            Efectivo
                          </label>
                        </div>
                        <div className="col text-left">
                          <input
                            className="form-check-input"
                            type="radio"
                            id="rdoBancarioPago"
                            name="rdoBancario"
                            checked={bancarioChecked}
                            defaultValue={bancarioChecked}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="rdoBancarioPago"
                          >
                            Transferencia
                          </label>
                        </div>
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-md-4 float-right">
                      <div className="row">
                        <div className="col-8 float-left text-left">
                          <h5>
                            <small>
                              <strong>Subtotal</strong>
                            </small>
                            <span className="badge badge-success ml-1 mr-1">
                              {items}
                            </span>
                            <small>
                              <strong>Productos:</strong>
                            </small>
                          </h5>
                        </div>
                        <div className="col">
                          <div className="row  float-right text-right ">
                            <h5>
                              <span className="badge badge-pill badge-warning ml-2 mr-3">
                                ${numberFormat(subtotal)} {/*+ IVA */}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-md float-right">
                      <div className="row">
                        <div className="col-8 float-left text-left">
                          <h5>
                            <small>
                              <strong>Envío:</strong>
                            </small>
                          </h5>
                        </div>
                        <div className="col">
                          <div className="row  float-right text-right ">
                            <h5>
                              <span className="badge badge-pill ml-2 mr-3">
                                ${numberFormat(Global.precioEnvio)}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-md float-right">
                      <div className="row">
                        <div className="col-8 float-left text-left">
                          <h5>
                            <small>
                              <strong>I.V.A.:</strong>
                            </small>
                          </h5>
                        </div>
                        <div className="col">
                          <div className="row  float-right text-right ">
                            <h5>
                              <span className="badge badge-pill ml-2 mr-3">
                                $
                                {numberFormat(
                                  (subtotal + Global.precioEnvio) * 0.16
                                )}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-8"></div>
                    <div className="col-md float-right">
                      <div className="row">
                        <div className="col-8 float-left text-left">
                          <h5>
                            <h5>
                              <small>
                                <strong>Total:</strong>
                              </small>
                            </h5>
                          </h5>
                        </div>
                        <div className="col">
                          <div className="row  float-right text-right ">
                            <h5>
                              <span className="badge badge-pill badge-warning ml-2 mr-3">
                                $
                                {numberFormat(
                                  subtotal +
                                    Global.precioEnvio +
                                    (subtotal + Global.precioEnvio) * 0.16
                                )}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form onSubmit={saveOrder}>
                <div className="form-row">
                  <div className="col-md-10 mt-3"></div>
                  <div className="col-md-2 mt-3">
                    <button
                      className="btn btn-warning btn-block"
                      type="submit"
                      disabled={subtotal === 0 && { true: "false" }}
                    >
                      <i className="fas fa-paper-plane mr-1"></i>Enviar
                    </button>
                  </div>
                </div>
              </form>
            </Fragment>
          )}
        </div>
      </section>
      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default Cart;
