import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert";
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import OrderItem from "./OrderItem";
import {
  numberFormat,
  getPedido,
  actualizaGuia,
  getNotificacion,
} from "../repository";
import swal from "sweetalert";
import SimpleReactValidator from "simple-react-validator";

const OrderDetailsAdmon = (props) => {
  const [status, setStatus] = useState(null);
  const [pedido, setPedido] = useState({});
  const [detallePedido, setDetallePedido] = useState({});
  const [source, setSource] = useState("");
  const [items, setItems] = useState(0);
  const [numeroGuia, setNumeroGuia] = useState(null);
  const [disabled, setdisabled] = useState(true);
  const [, setForceUpdate] = useState(0);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    })
  );

  const Guardar = async (idPedido) => {
    var user = JSON.parse(sessionStorage.getItem("usuario"));
    if (validator.current.allValid()) {
      setdisabled(true);
      actualizaGuia(idPedido, 3, numeroGuia).then((response) => {
        if (response.status === 200) {
          swal("Aviso", response.data.message, "success").then(() => {
            getNotificacion(idPedido, user[0].idCliente, "0");
            window.location = "/Admon";
          });
        }
      });
    } else {
      swal("Aviso", "Favor de ingresar un número de guía", "warning");
      validator.current.showMessages();
      setForceUpdate(1);
    }
  };

  const getOrder = () => {
    try {
      //params url
      var idPedido = props.match.params.idPedido;
      var source = props.match.params.source;
      setSource(source);
      getPedido(idPedido)
        .then((response) => {
          if (response.status === "success") {
            var cabeceraPedido = response.result.recordsets[0];
            var detallePedido = response.result.recordsets[1];
            setPedido(response.result.recordsets[0]);
            setNumeroGuia(cabeceraPedido[0].NumeroGuia);
            setDetallePedido(response.result.recordsets[1]);
            var items = 0;
            //Calculo los items
            detallePedido.forEach((item) => {
              items += item.Cantidad;
            });
            setStatus("success");
            setItems(items);
          } else {
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) =>
          Swal("Error obtener la información [Pedido]", err, "error")
        );
    } catch (err) {
      Swal("Error obtener la información [Pedido]", err, "error");
    }
  };

  useEffect(
    () => {
      validator.current.hideMessages();
      getOrder();
    },
    // eslint-disable-next-line
    [props.match.params.idPedido, props.match.params.source]
  );

  return (
    <div>
     
      {/* HEADER */}
      <Header
        title={
          status === "success" ? "Pedido #" + pedido[0].idPedido : "Pedido"
        }
        class1="row py-1 bg-warning text-black"
        class2="col-md-6"
        class3="fa fa-money-check-alt"
      />
      <section id="actions" className="py-3 mb-2">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <Link
                to={source === "1" ? "/home" : "/Admon"}
                className="btn btn-secondary btn-block mb-2"
              >
                <i className="fas fa-arrow-left"></i> Regresar
              </Link>
            </div>
            {status === "success" && (
              <div className="col-md-9">
                <h6>
                  <strong>Fecha: </strong>
                  {pedido[0].Fecha}
                </h6>

                <h6>
                  <strong>Guía: </strong>
                  {pedido[0].NumeroGuia}
                </h6>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* CONTENT */}
      <section id="items">
        <form>
          <div className="container">
            {status !== "success" && (
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6 text-center">
                  <div className="text-center">
                    <h6>Cargando...</h6>
                    <div
                      className="spinner-border text-warning"
                      role="status"
                      aria-hidden="true"
                    ></div>
                  </div>
                  <div className="col-md-3"></div>
                </div>
              </div>
            )}
            {status === "success" && (
              <Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <ul className="list-group">
                      {detallePedido.map((item, index) => (
                        <OrderItem item={item} key={index} />
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-12 text-right">
                    <h5>
                      <span className="badge badge-warning ml-1 mr-1">
                        {items}
                      </span>
                      <small>
                        <strong>Productos:</strong>
                      </small>
                      <span className="badge badge-pill badge-warning ml-2 mr-3">
                        ${numberFormat(pedido[0].Total)}
                      </span>
                    </h5>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mt-2">
                    Guía:
                    <input
                      name="txtNumeroGuia"
                      type="text"
                      className="form-control"
                      value={numeroGuia}
                      onChange={(e) => {
                        setNumeroGuia(e.target.value);
                      }}
                      disabled={disabled}
                    />
                    {validator.current.message(
                      "txtNumeroGuia",
                      numeroGuia,
                      "required|string"
                    )}
                  </div>
                  <div className="col-md-6 mt-3 d-flex">
                    <div className="col-md mt-3">
                      <button
                        className="btn btn-block btn-warning"
                        disabled={!disabled}
                        onClick={(e) => {
                          e.preventDefault();
                          setdisabled(false);
                        }}
                      >
                        Editar
                      </button>
                    </div>
                    <div className="col-md mt-3">
                      <button
                        className="btn btn-block btn-warning"
                        disabled={disabled}
                        onClick={(e) => {
                          e.preventDefault();
                          Guardar(pedido[0].idPedido);
                          setForceUpdate(0);
                        }}
                      >
                        Guardar
                      </button>
                    </div>
                    <div className="col-md mt-3">
                      <button
                        className="btn btn-block btn-warning"
                        disabled={disabled}
                        onClick={(e) => {
                          e.preventDefault();
                          setdisabled(true);
                          validator.current.hideMessages();
                          getOrder();
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </form>
      </section>
      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default OrderDetailsAdmon;
