import axios from "axios";
import global from "./components/Global";

export function login(data) {
  var usuario = data.usuario;
  var contrasenia = data.contrasenia;

  var apiCall =
    global.apiRESTUrl + "/obtieneUsuario/" + usuario + "/" + contrasenia;
  

  return (
    axios
      .get(apiCall)
      .then((response) => {     
        if (response.data.status === "success") {
          //Usuario       
          sessionStorage.setItem(
            "usuario",
            JSON.stringify(response.data.result)
          );
          //Token
          sessionStorage.setItem("x-access-token", response.data.token);
          //Token expira en 12 horas
          sessionStorage.setItem(
            "x-access-token-expiration",
            Date.now() + 12 * 60 * 60 * 1000
          );
        }
        return response.data;
      })
      //.catch(err => Promise.reject(err));
      .catch((error) => {       
        return error.response.data;
      })
  );
}

export function loginNew2(usuario, contrasenia) {
  var apiCall = global.apiRESTUrlNET + "/Users/getUser/" + usuario + "/" + contrasenia;
  return (
    axios
      .get(apiCall)
      .then((response) => {       
        if (response.status === 200) {
          sessionStorage.setItem("usuario", JSON.stringify(response.data));      
          //Token
          sessionStorage.setItem("x-access-token", response.data.token);
          //Token expira en 12 horas
          sessionStorage.setItem(
            "x-access-token-expiration",
            Date.now() + 12 * 60 * 60 * 1000
          );
        }
        return response;
      })    
      .catch((error) => {       
        return error.response;
      })
  );
}


export function emailExiste(correo) {
  var apiCall = global.apiRESTUrlNET + "/Users/getUserByEmail/" + correo + "/";
  return (
    axios
      .get(apiCall)
      .then((response) => {               
        //validar response si es diferente de 0.       
        if (response.status === 200) {     
        //  console.log(response.data);
          return response.data;   
        }        
      })    
      .catch((error) => {       
        return error.response;
      })
  );
}

export function isAuthenticated() {
  //return false;

  return (
    sessionStorage.getItem("x-access-token") &&
    sessionStorage.getItem("x-access-token-expiration") > Date.now()
  );
}

export function getSucursales(latitude, longitude) { 
  var apiCall =
    global.apiRESTUrl + "/obtieneSucursales/" + latitude + "/" + longitude;  
  return (
    axios
      .get(apiCall)
      .then((response) => {
       
        return response.data;
      })     
      .catch((error) => {       
        return error.response.data;
      })
  );
}

export function getMarcas() {
  var apiCall = global.apiRESTUrl + "/obtieneMarcas";
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export function getVehiculos(idMarca) {
  var apiCall = global.apiRESTUrl + "/obtieneVehiculos/" + idMarca;
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export function getModelos(idTipoVehiculo) {
  var apiCall = global.apiRESTUrl + "/obtieneModelos/" + idTipoVehiculo;
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export function getFamilias(idTipoVehiculo, modelo) {
  var apiCall =
    global.apiRESTUrl + "/obtieneFamilias/" + idTipoVehiculo + "/" + modelo;
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {      
      return error.response.data;
    });
}

export function getProductos(idTipoVehiculo, idFamilia, modelo) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneProductos/" +
    idTipoVehiculo +
    "/" +
    idFamilia +
    "/" +
    modelo; 
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export function numberFormat(value) {
  return new Intl.NumberFormat("en-US", {
    style: "decimal", //currency
    currency: "MXN",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
  }).format(value);
}

export function addDefaultSrc(ev) {
  ev.target.src = global.foto_nd;
}

export function getInventarioCEDIS(idAlmacen, codigo, idMarca) {
  var apiCall =
    global.apiRESTUrl +
    "/getInventarioCEDIS/" +
    idAlmacen +
    "/" +
    codigo.toUpperCase() +
    "/" +
    idMarca; 
    console.log(apiCall);
  return axios
    .get(apiCall ,{ timeout:5000 })
    .then((response) => {    
      return response;
    })
    .catch((error) => {      
      return error.response;
    });
}

export async function getInventarioCEDISAsync(idAlmacen, codigo, idMarca) {
  var apiCall =
    global.apiRESTUrl+
    "/getInventarioCEDIS/" +
    idAlmacen +
    "/" +
    codigo.toUpperCase() +
    "/" +
    idMarca;
  const response = await axios.get(apiCall, { timeout:5000 }).catch((error) => {    
    return error;
  });       
  try {   
    if (response.status === 200) 
    {     
      return response.data.result[0].inventario;
    }    
    else
    {
      return 0;
    }  
  } catch (error) {
    console.log("error catch");
    return 0;
  }  
}

export  function getInventarioNonAsync(idAlmacen, codigo, idMarca) {
  var apiCall =
    global.apiRESTUrl +
    "/getInventarioCEDIS/" +
    idAlmacen +
    "/" +
    codigo +
    "/" +
    idMarca;   
    return axios
    .get(apiCall)
    .then((response) => {         
      return response.data.result[0].inventario;
    })
    .catch((error) => {     
      return 99;
    });
}



export function getInventarioDetalladoCEDIS(codigo) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneInventarioDetalladoCEDIS/" + codigo.toUpperCase();      
  return axios
    .get(apiCall)
    .then((response) => {  
      return response;
    })
    .catch((error) => {     
      return error;
    });
}

export function crearUser() {
  var apiCall = global.apiRESTUrlNET + "/login/CrearUser" ;
  return (
    axios
      .get(apiCall)
      .then((response) => {
           
        return response;
      })    
      .catch((error) => {       
        return error.response;
      })
  );
}

export async function agregaDatosFacturacion(
  idClienteGovi,
  Nombre,
  Apellidos,
  Email,
  Empresa,
  RFC,
  Telefono,
  TelOficina,
  Calle,
  NumExterior,
  NumInterior,
  Colonia,
  Ciudad,
  CodigoPostal,
  Pais,
  Estado,
  TipoTarjeta,
  NombreTarjeta,
  NumeroTarjeta,
  MesTarjeta,
  AnioTarjeta
) {
  var apiCall = global.apiRESTUrl + "/agregaDatosFacturacion"; 
  const response = await axios
    .post(apiCall, {
      idClienteGovi: idClienteGovi,
      Nombre: Nombre,
      Apellidos: Apellidos,
      Email: Email,
      Empresa: Empresa,
      RFC: RFC,
      Telefono: Telefono,
      TelOficina: TelOficina,
      Calle: Calle,
      NumExterior: NumExterior,
      NumInterior: NumInterior,
      Colonia: Colonia,
      Ciudad: Ciudad,
      CodigoPostal: CodigoPostal,
      Pais: Pais,
      Estado: Estado,
      TipoTarjeta: TipoTarjeta,
      NombreTarjeta: NombreTarjeta,
      NumeroTarjeta: NumeroTarjeta,
      MesTarjeta: MesTarjeta,
      AnioTarjeta: AnioTarjeta
    })
    .catch((error) => {    
      console.log(error);
      return error;
    });

  try {   
    if (response.data.status === "success") {     
      return response;
    } else return 0;
  } catch (error) {
    return 0;
  }
}

export async function agregaPedidos(
  idClienteGovi,
  idAlmacen,
  total,
  consignado,
  idClienteWeb
) {
  var apiCall = global.apiRESTUrl + "/agregaPedidos"; 
  const response = await axios
    .post(apiCall, {
      idClienteGovi: idClienteGovi,
      idAlmacen: idAlmacen,
      total: total,
      consignado: consignado,
      idClienteWeb: idClienteWeb,
    })
    .catch((error) => {    
      return error;
    });

  try {   
    if (response.data.status === "success") {     
      return response.data.result[0].idPedido;
    } else return 0;
  } catch (error) {
    return 0;
  }
}



export async function actualizaGuia(
  idPedidoP, estatusP, numeroGuiaP
) {
  var apiCall = global.apiRESTUrl + "/actualizaGuia"; 
  const response = await axios
    .post(apiCall, {
      idPedido: idPedidoP,
      estatus: estatusP,
      numeroGuia: numeroGuiaP,
    }).then((response) => 
    {
     
        return response;
        
    })
    .catch((error) => {   
      return error;
    });
    try {
        return response;
    } catch (error) 
    {
     return error;
    }
}

export async function agregaDetallePedidos(
  idPedido,
  producto,
  descripcion,
  cantidad,
  precio,
  idMarca,
  marca
) {
  var apiCall = global.apiRESTUrl + "/agregaDetallePedidos";

  const response = await axios
    .post(apiCall, {
      idPedido: idPedido,
      producto: producto,
      descripcion: descripcion,
      cantidad: cantidad,
      precio: precio,
      idMarca: idMarca,
      marca: marca,
    })
    .catch((error) => {
      return error;
    });
  try {
    if (response.data.status === "success") {   
      return response.data.result[0].Message;
    } else return 0;
  } catch (error) {
    return 0;
  }
}

export function getPedidos(idCliente, idAlmacen, top) {
  var apiCall = global.apiRESTUrl + "/obtienePedidos/" + idCliente +"/" +idAlmacen +  "/" + top;  
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export function getPedidosAdmon() {
  var apiCall = global.apiRESTUrl + "/obtienePedidosAdmon";  
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export function getNumPedidos(idCliente, idAlmacen) {
  var apiCall = global.apiRESTUrl + "/obtieneNumPedidos/" + idCliente+ "/" + idAlmacen;   
  //console.log(apiCall);
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {      
      return error.response.data;
    });
}

export function getPedido(idPedido) {
  var apiCall = global.apiRESTUrl + "/obtienePedido/" + idPedido;
//  console.log(apiCall);
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {    
      return error.response.data;
    });
}

export function getAllVehiculos() {
  var apiCall = global.apiRESTUrl + "/obtieneTodosVehiculos";
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {      
      return error.response.data;
    });
}

export function getProductosByCode(codigo, equivalencias) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneProductosByCode/" +
    codigo +
    "/" +
    equivalencias;
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}


export function getNotificacion(idPedido, idClienteTienda, tipoPago) {
  var apiCall =
    global.apiRESTUrlNET +
    "/Users/getMail/" +
    idPedido +
    "/" +
    idClienteTienda +
    "/" + tipoPago;
    //console.log(apiCall);
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export async function getProductosByCodePlantilla(codigo, equivalencias) {
  var apiCall =
    global.apiRESTUrl +
    "/obtieneProductosByCodePlantilla/" +
    codigo +
    "/" +
    equivalencias;    
  return await axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export function getProductosPlantilla() {
  const apiCall =
    global.apiRESTUrl +
    "/obtieneProductosPlantilla/"
    
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {      
      return error.response.data;
    });
}

export function getProductosByCodeDetalle(codigo) {
  const apiCall =
    global.apiRESTUrl +
    "/obtieneProductosByCodeDetalle/" +
    codigo
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export function getProductosByDescripcion(desc) {
  var apiCall = global.apiRESTUrl + "/obtieneProductosByDescripcion/" + desc;
  return axios
    .get(apiCall)
    .then((response) => {   
      return response.data;
    })
    .catch((error) => {      
      return error.response.data;
    });
}

export function getMarcasProductosNuevos() {
  var apiCall = global.apiRESTUrl + "/obtieneMarcasProductosNuevos/";
  return axios
    .get(apiCall)
    .then((response) => {   
      return response.data;
    })
    .catch((error) => {      
      return error.response.data;
    });
}


export function getFamiliasProductosNuevos(fechaInicial, fechaFinal,idMarca) {
  var apiCall = global.apiRESTUrl + "/obtieneFamiliasProductosNuevos/" +fechaInicial +"/"+ fechaFinal+"/"+  idMarca; 
  return axios
    .get(apiCall)
    .then((response) => {   
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}


export function getDatosFacturacion(idClienteGOVI) {
  var apiCall = global.apiRESTUrl + "/obtieneDatosFacturacion/" +idClienteGOVI; 
  return axios
    .get(apiCall)
    .then((response) => {   
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export function getProductosNuevos(fechaInicial, fechaFinal,idMarca, idFamilia) {
  var apiCall = global.apiRESTUrl + "/obtieneProductosNuevos/" +fechaInicial +"/"+ fechaFinal+"/"+  idMarca+ "/"+ idFamilia; 
  return axios
    .get(apiCall)
    .then((response) => {   
      return response.data;
    })
    .catch((error) => {      
      return error.response.data;
    });
}

export function getProductosByDescCode(codigo) {
  var apiCall = global.apiRESTUrl + "/obtieneProductosByDescCode/" + codigo;
  return axios
    .get(apiCall)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export function getRecomendados()
{
  var apiCall = global.apiRESTUrl + "/obtieneRecomendaciones/";
  return axios
    .get(apiCall)
    .then((response) => {
     // console.log(response);
      return response.data;
    })
    .catch((error) => {     
      return error.response.data;
    });
}

export function getPrivilegios() {
  var apiCall = global.apiRESTUrl + "/obtienePrivilegios"; 
  return axios
    .get(apiCall)
    .then((response) => {   
     //console.log(response.data.result);
     // sessionStorage.setItem("privilegios", response.data);
      return response.data;
    })
    .catch((error) => {      
      return error;
    });
}

export function registrarUsuario(nombre,correo) {
  var apiCall = global.apiRESTUrlNET + "/Users/getNewuser/" + nombre.replace(' ', '%20') + "/" + correo + "/";
  return axios
    .get(apiCall)
    .then((response) => {
     if( response.data !== "El correo proporcionado ya existe.") {
      sessionStorage.setItem("usuario", JSON.stringify(response.data));
     }  
      return response;
    })
    .catch((error) => {     
      return error;
    });
}



export function getUbicacionesGrupoNOCEDIS() {
  const apiCall = global.apiRESTUrlGROB + "/obtieneUbicacionesGrupoNOCEDIS";
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}


export function recuperarContrasenia(correo) {
  var apiCall = global.apiRESTUrlNET + "/Users/getRecoverPassword/" + correo + "/";
  
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {     
      return error;
    });
}


export function getUbicacionSucursales(ordenGrupo) {
  const apiCall = global.apiRESTUrlGROB + "/obtieneUbicacionSucursales/" + ordenGrupo;
  
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
  
}
  

export function obtieneFamiliaProductos(idFamilia) {
  const apiCall = global.apiRESTUrlGROB + "/obtieneFamiliaProductos/" + idFamilia;
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}
export function getFamiliasImg() {
  const apiCall = global.apiRESTUrlGROB + "/obtieneFamiliasImg";
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export function getConfig(config) {
  const apiCall = global.apiRESTUrlGROB + "/obtieneConfiguraciones/" + config;
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export async function sendMail(
  pMailSender,
  pMailSenderPwd,
  pMailReceiver,
  pNombre,
  pEmail,
  pTelefono,
  pComentarios,
  pEstado,
  pCiudad
) {
  const apiCall = global.apiRESTUrlGROB + "/enviarEMail";
  const response = await axios
    .post(apiCall, {
      mailSender: pMailSender,
      mailSenderPwd: pMailSenderPwd,
      mailReceiver: pMailReceiver,
      nombre: pNombre,
      email: pEmail,
      telefono: pTelefono,
      comentarios: pComentarios,
      estado: pEstado,
      ciudad: pCiudad,
    })
    .catch((error) => {
      return error;
    });
  try {
    if (response.data.status === "success") {
      return 0;
    } else {
      return 1;
    }
  } catch (error) {
    console.log(error);
    return 1;
  }
}

export function getDepartamentos() {
  const apiCall = global.apiRESTUrlGROB + "/obtieneDepartamentos";
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}


export function getEstados() {
  const apiCall = global.apiRESTUrlGROB + "/obtieneEstados";
  return axios
    .get(apiCall)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}

export async function comprobarCaptcha(captchA) {
  const apiCall = global.apiRESTUrlGROB + "/compruebaCaptcha";

  return await axios.post(apiCall, { captcha: captchA }).catch((error) => {
    console.log(error);
    return error;
  });
}
