// components/BannerButton.js
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLocationDot, faCartShopping, faFile } from '@fortawesome/free-solid-svg-icons';

const BannerButton = () => {
  return (
    <Fragment>
    <div style={styles.banner}>
      <div className='col-xs mr-4'>
      <button className='btn'>   <FontAwesomeIcon icon={faLocationDot} style={styles.icon} />
      </button>
      </div>
      <div style={styles.content}>
        <h2 style={styles.title}> <a  className='text-white' href='/Locations'> Ubica tu Tienda</a> </h2>
        <p style={styles.description}>
          Tenemos mas de 90 sucursales a nivel nacional
        </p>
      </div>
    </div>


 <div style={styles.banner}>
      <div className='col-xs mr-4'>
      <button  className='btn'>  <FontAwesomeIcon icon={faCartShopping} style={styles.icon} />
      </button>
      </div>
      <div style={styles.content}>
        <h2 style={styles.title}>   <a  className='text-white' > Clic, compra y recoge </a></h2>
        <p style={styles.description}>
          Haz tu pedido en Linea
        </p>
      </div>
    </div>

    <div style={styles.banner}>
      <div className='col-xs mr-4'>
      <button  className='btn'>      <FontAwesomeIcon icon={faFile} style={styles.icon} />
      </button>
      </div>
      <div style={styles.content}>
        <h2 style={styles.title}>   <a  className='text-white' > Folleto promocional </a></h2>
        <p style={styles.description}>
          Las mejores ofertas y promociones
        </p>
      </div>
    </div>
    </Fragment>
  );
};

const styles = {
  banner: {
    width: '100%',
    height: '90px',
    backgroundColor: 'Black', // Color de fondo del banner
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '10px',
    overflow: 'hidden',
    color: '#fff',
    textAlign: 'center',
    margin:"10px",
  },
  content: {
    maxWidth: '450px',
    
    fontSize:'15',
   
  },
  title: {
    fontSize: '25px',
    margin: '0 0 10px',
    textAlign:'left',
    fontWeight: 'bold',
    
    
  },
  description: {
    fontSize: '12px',
    marginBottom: '20px',
  },
  button: {
    backgroundColor: 'Black',
    color: 'Black',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '16px',
    cursor: 'pointer',
  },
  icon: {
    fontSize: '40px',
    color: 'orange',
    backgroundColor: '#000000',
  },
};

export default BannerButton;