import { Fragment } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const ExpertsTips = () => {
  return (
    <Fragment>
      <Header />

      <div className="container mt-3 mb-3">
        <div className="row">
          <div className="col-lg-12 text-center"></div>
        </div>
        <div className="row-flex">
          <div className="col-lg">
            <h2 className="section-heading  text-center">
             En construccion....
            </h2>

           </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default ExpertsTips;
