import React, { useState, Fragment, useEffect } from "react";
import MenuCatalogo from "../components/MenuCatalogo";
import ProductItem from "../components/ProductItem";
import MenuGOVI from "../components/MenuGOVI";
import Footer from "../components/Footer";
import MenuSuspension from "../components/MenuSuspension";
import Header from "../components/Header";
import Swal from "sweetalert";
import Swal2 from "sweetalert2";
import BannerButton from "../components/BannerButton";
import Global from "../components/Global";
import { registerLicense } from "@syncfusion/ej2-base";
import MenuAlt from "../components/MenuAlt";
import {
  CarouselComponent,
  CarouselItemsDirective,
  CarouselItemDirective,
} from "@syncfusion/ej2-react-navigations";
import FooterMainPage from "../components/FooterMainPage";
//import licenciaSyncfusion from "../components/Global";

registerLicense(Global.licenciaSyncfusion);

const MainPage = () => {
  const [productos, setProductos] = useState([]);
  const [status, setstatus] = useState("");
  const [actualizacionCarrito, setCarrito] = useState(0);
  //const [barActivo, setBarActivo] =   useState("descripcion");
  const [busquedaOpcion, setBusquedaOpcion] = useState(1);

  const actualizarproductos = (postProductos) => {
    setProductos(postProductos);
  };

  async function actualizaBusqueda(opcion)
    {
        if(opcion === undefined)
          {
              setBusquedaOpcion(1);
              return 1;
          }
          else{
            setBusquedaOpcion(opcion);
            return opcion;
          }
    }

  // const idSucursal = 1;

  async function addToCart(item, cant) {
    try {
      //console.log("agregando al carrito");
      // let cart = sessionStorage.getItem("cart") ? JSON.parse(sessionStorage.getItem("cart")) : {};
      let cart = JSON.parse(sessionStorage.getItem("cart") || "{}");
      console.log(cart);
      let idProducto = item.idProducto;
      cart[idProducto] = cart[idProducto] ? cart[idProducto] : item;
      var numero = cart[idProducto].Cantidad;
      if (numero === undefined) {
        cart[idProducto].Cantidad = 0;
      }
      cart[idProducto].Cantidad += cant;
      sessionStorage.setItem("cart", JSON.stringify(cart));
      // navBarRef.current.getCartItems();
      Swal2.fire({        title:"Producto agregado",
         text: "Cuenta con [" +
          cart[idProducto].Cantidad +
          "] productos [" +
          cart[idProducto].Codigo +
          "] en su Carrito de Compras.",
        icon:"success",
        confirmButtonColor:"orange",}
      );
      setCarrito(actualizacionCarrito + 1);
    } catch (error) {
      Swal(
        "Error en Carrito de Compras",
        "No se pudo agregar su Producto al Carrito de Compras.",
        "error"
      );
      console.log(error);
    }
  }

  useEffect(() => {
    if (productos.length > 0) {
      setstatus("success");
    } else {
      setstatus("");
    }
  
   
  }, [productos, actualizacionCarrito, busquedaOpcion]);

  return (
    <div className="">
      <Header prodcarrito={actualizacionCarrito} />

      <div className="row-banner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-2">
              {/* <MenuGOVI></MenuGOVI> */}
              <MenuAlt></MenuAlt>
            </div>
            <div className="col-md bg-white mt-3 mb-3">
             
              <MenuCatalogo actualizarproductos={actualizarproductos} opcionNav = {busquedaOpcion} />
         
            </div>
          </div>
        </div>
      </div>

      {status === "success" && (
        <Fragment>
          <div className="container">
            <div className="row mt-3">
              <div className="col-md-3"></div>
              <div className="col-md-6 text-center">
                <h6>
                  <span className="badge badge-warning ml-1 mr-1">
                    {productos.length}
                  </span>
                  <small>
                    <strong>Producto(s) encontrado(s)</strong>
                  </small>
                </h6>
                <div className="col-md-3"></div>
              </div>
            </div>
            <div className="row">
              {productos.map((item, index) => (
                <ProductItem
                  item={item}
                  index={index}
                  key={index}
                  add={addToCart}
                />
              ))}
            </div>
          </div>
        </Fragment>
      )}

      {status !== "success" && (
        <Fragment>
          <div className="row-banner2">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-auto">
                  <MenuSuspension></MenuSuspension>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="container">
              <div className="row">
                <div className="col-md mt-3">
                  <CarouselComponent>
                    <CarouselItemsDirective>
                  { /*  <CarouselItemDirective template='<figure class="img-container"><img src="https://storagegovi.blob.core.windows.net/govimx-imgs/img_centro_tienda.PNG" alt="cardinal" style="height:100%;width:100%;" /><figcaption class="img-caption"></figcaption></figure>' />   */}
                      <CarouselItemDirective template='<figure class="img-container"><img src="https://storagegovi.blob.core.windows.net/govimx-imgs/promo_amortiguadores.jpg" alt="cardinal" style="height:100%;width:100%;" /><figcaption class="img-caption"></figcaption></figure>' />
                      <CarouselItemDirective template='<figure class="img-container"><img src="https://storagegovi.blob.core.windows.net/govimx-imgs/tips_1.jpg" alt="kingfisher" style="height:100%;width:100%;" /><figcaption class="img-caption"></figcaption></figure>' />
                      <CarouselItemDirective template='<figure class="img-container"><img src="https://storagegovi.blob.core.windows.net/govimx-imgs/tips_2.jpg" alt="keel-billed-toucan" style="height:100%;width:100%;" /><figcaption class="img-caption"></figcaption></figure>' />
                      <CarouselItemDirective template='<figure class="img-container"><img src="https://storagegovi.blob.core.windows.net/govimx-imgs/tips_3.jpg" alt="yellow-warbler" style="height:100%;width:100%;" /><figcaption class="img-caption"></figcaption></figure>' />
                    </CarouselItemsDirective>
                  </CarouselComponent>
                </div>
                <div className="col-md-auto align-self-center">
                  <div className="col">
                    <BannerButton></BannerButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}

     {/*  <Footer ></Footer> */}
     <FooterMainPage opcionNav={actualizaBusqueda}></FooterMainPage> 
    </div>
  );
};

export default MainPage;
