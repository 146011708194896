import { MenuComponent } from "@syncfusion/ej2-react-navigations";
import React, { useState, useEffect } from "react";


const MenuSuspension = () => {
  const [isVertical, setIsVertical] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {

      if(window.innerWidth < 768) {
      setIsMobile(window.innerWidth <= 768);
      setIsVertical(true);
      }
      else
      {
        setIsVertical(false);
      }  
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const menuItemsSuspension = [
    {
      text: "Suspensión",
      items: [
        { text: "Abrasadera" , url:'Products#Nav-1' },
        { text: "Barras" , url:'Products#Nav-1'},
        { text: "Bases de amortiguador", url:'Products#Nav-1' },
        { text: "Bujes", url:'Products#Nav-1' },
        { text: "Elevador de barra de torsión", url:'Products#Nav-1' },
        { text: "Excéntricos" , url:'Products#Nav-1'},
        { text: "Gomas estabilizadoras", url:'Products#Nav-1' },
        { text: "Horquilla", url:'Products#Nav-1' },
        { text: "Hules de tirante", url:'Products#Nav-1' },
        { text: "Pasadores", url:'Products#Nav-1' },
        { text: "Resortes", url:'Products#Nav-1' },
        { text: "Rotula", url:'Products#Nav-1' },
        { text: "Topes de amortiguador" , url:'Products#Nav-1'},
        { text: "Tornillos estabilizadores" , url:'Products#Nav-1'},
      ],
    },

    {
      text: "Tracción delantera",
      items: [
        { text: "Balero" , url:'Products#Nav-2' },
        { text: "Balero doble" , url:'Products#Nav-2'},
        { text: "Cubre polvo" , url:'Products#Nav-2'},
        { text: "Juntas V.C." , url:'Products#Nav-2'},
        { text: "Mazas de rueda de 1A. Generación" , url:'Products#Nav-2' },
        { text: "Mazas de rueda de 2A. Generación" , url:'Products#Nav-2'},
        { text: "Mazas de rueda de 3A. Generación" , url:'Products#Nav-2'},
      ],
    },

    {
      text: "Dirección Automotriz",
      items: [
        { text: "Brazo Auxiliar", url:'Products#Nav-3' },
        { text: "Brazo PitMan", url:'Products#Nav-3' },
        { text: "Cremalleras" , url:'Products#Nav-3'},
        { text: "Cubre polvo", url:'Products#Nav-3' },
        { text: "Gomas de cremallera", url:'Products#Nav-3' },
        { text: "Pernos" , url:'Products#Nav-3'},
        { text: "Terminales", url:'Products#Nav-3' },
        { text: "Tubo unión", url:'Products#Nav-3' },
        { text: "Varilla", url:'Products#Nav-3' },
        { text: "Varilla lateral", url:'Products#Nav-3' },
        { text: "Vieletas" , url:'Products#Nav-3'},
      ],
    },
    {
      text: "Soportes",
      items: [
        { text: "Soporte motor" , url:'Products#Nav-4' },
        { text: "Soporte motor (Torsión)" , url:'Products#Nav-4' },
        { text: "Soporte transmision" , url:'Products#Nav-4'},
      ],
    },
    {
      text: "Amortiguadores" , url:'Products#Nav-1',
  
    },
  ];

  return (
    <MenuComponent
      items={menuItemsSuspension}
      id="menu2"
      title=""
      orientation={isVertical ? "Vertical" : "Horizontal"} // Cambia la orientación según el estado
      cssClass="custom-menu2"
     
    />
  );
};
export default MenuSuspension;
