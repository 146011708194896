import React, { useState, useEffect } from "react";
import Global from "./Global";
import { obtieneFamiliaProductos } from "../repository";
import Modal360Viewer from "../components/Product360ViewerPromo";

const ProductItems = (props) => {
  const [loading, setLoading] = useState(true);
  const [productosFamilia, setProductosFamilia] = useState([]);
  const [selectedFamily, setSelectedFamily] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const obtieneProductosFam = (idFamilia) => {
    obtieneFamiliaProductos(idFamilia)
      .then((response) => {
        if (response.status === 200) {
          setProductosFamilia(response.data.result);
        } else {
          alert("Error obtener la información");
          setProductosFamilia([]);
        }
      })
      .catch(
        (err) => alert("Error obtener la información" + err),
        setProductosFamilia([])
      );
  };

  useEffect(() => {
    obtieneProductosFam(props.idFamilia);
   
    setLoading(false);
  }, [props.idFamilia]);

  const open360Viewer = (idFamilia, index) => {
   console.log("open modal");
    setSelectedFamily(idFamilia);
    setSelectedIndex(index);

    console.log( idFamilia, index);
  };

  const close360Viewer = () => {
    //console.log("close modal")
    setSelectedFamily(null);
    setSelectedIndex(null);
  };

  return (
    <div className="row mt-3" key={props.idFamilia + props.Codigo}>
      {loading ? (
        <div className="col-md-12 text-center">
          <p>Cargando...</p>
          <div
            className="spinner-border text-warning"
            role="status"
            aria-hidden="true"
          ></div>
        </div>
      ) : (
        productosFamilia.map((item, index) => (
          <div className="col-md-4 card-group" key={index}>
            <div className="card mb-4" style={{ backgroundColor: "#F8F8F8" }}>
              <img
                className="card-img-top"
                src={Global.urlFotos360 + item.DefaultImage}
                alt={item.Codigo}
                style={{
                  cursor: "pointer",
                  maxWidth: 100 + "%",
                  height: 170 + "px",
                  objectFit: "cover",
                }}
                onClick={() => open360Viewer(item.idFamilia, index)}
                data-toggle="modal"
                data-target={"#modal360" + item.idFamilia + index}
              />
              {selectedFamily === item.idFamilia && selectedIndex === index && (
                <Modal360Viewer
                  index={index}
                  item={item}
                  closeModal={close360Viewer}
                />
              )}
              <div
                className="card-footer"
                style={{ backgroundColor: "#F8F8F8" }}
              >
                <h6 className="text-center"> {item.Familia} </h6>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default ProductItems;
