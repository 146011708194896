import React, { Fragment } from "react";

const MenuAlt = () => {
 

  return (

<Fragment>

<input type='checkbox' id='mmeennuu' /> 
<label className='menu' htmlFor='mmeennuu'>

<div className='barry'>
	<span className='bar'></span>
	<span className='bar'></span>
	<span className='bar'></span>
	<span className='bar'></span>
   
</div>
	
<ul>
	 {/* <li><a id='home' href='/ourmark'>Nuestra Marca</a></li> */}
   <li><a id='nosotros' href='/About'>Nosotros</a></li>
	<li><a id='productos' href='/Products'>Productos</a></li>
	<li><a id='locations' href='/Locations'>Sucursales</a></li>
	<li><a id='session' href='/Login'>Iniciar Sesión</a></li>
    <li><a id='register' href='/Register'>Registrarse</a></li>
    <li><a id='mechanics' >GROB mecánicos</a></li>  
     <li><a id='experts' >Tips de expertos</a></li>
    
     <li><a id='contact' href='/Contact'>Contacto</a></li>
</ul>

</label>

    </Fragment>
  

  );
};
export default MenuAlt;
