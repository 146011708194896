import React, { useEffect, useState } from "react";
const FooterMainPage = ({ opcionNav }) => {
  const [opcionNavDef] = useState(1);

  function seleccionarOpcion(opcion) {
    switch (opcion) {
      case 1:
        opcionNav(opcion);
        break;
      case 2:
        opcionNav(opcion);
        break;
      case 3:
        opcionNav(opcion);
        break;
      case 4:
        opcionNav(opcion);
        break;
      default:
        opcionNav(opcionNavDef);
        break;
    }
  }

  useEffect(() => {
    opcionNav(opcionNavDef);
  }, [opcionNavDef]);

  return (
    <div className="row-negro-inferior ">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="row-flex mt-2">
              <div className="col">
                <a className="font-weight-bold  italic  text-white">
                  Encuentra
                </a>
              </div>
              <div className="col">
                <a className=" text-white  italic " href="/Locations">
                  Sucursales GOVI
                </a>
              </div>
              <div className="col">
                <a
                  className="text-white  italic "
                  href="#marca"
                  onClick={() => seleccionarOpcion(2)}
                >
                  Buscar por marca
                </a>
              </div>
              <div className="col">
                <a
                  className=" text-white italic "
                  href="#vehiculo"
                  onClick={() => seleccionarOpcion(3)}
                >
                  Buscar por vehículo
                </a>
              </div>
              <div className="col">
                <a
                  className=" text-white italic "
                  href="#codigo"
                  onClick={() => seleccionarOpcion(4)}
                >
                  Buscar por código
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row-flex  mt-2">
              <div className="col">
                <a className="font-weight-bold text-white italic">
                  Información
                </a>
              </div>
              <div className="col">
                <a className=" text-white italic " href="/About">
                  Acerca de GOVI
                </a>
              </div>
              <div className="col">
                <a className=" f text-white italic  ">Bolsa de trabajo</a>
              </div>
              <div className="col">
                <a className="  text-white italic ">Expansión de tiendas</a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="row-flex  mt-2">
              <div className="col">
                <a className="font-weight-bold text-white">
                  Servicio al Cliente
                </a>
              </div>
              <div className="col">
                <a
                  className="  text-white italic "
                  href="https://facturacion.govinet.app/"
                >
                  Facturación electrónica
                </a>
              </div>
              <div className="col">
                <a className="text-white italic ">Compra y recoge</a>
              </div>
              <div className="col">
                <a className="  text-white italic " href="/Contact">
                  Contacto
                </a>
              </div>
              <div className="col">
                <a className="  text-white italic  ">GOVI premios</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterMainPage;
