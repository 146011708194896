import React, { useState, useEffect, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import * as validadorEmail from "validator";
import {
  getDatosFacturacion,
  agregaDatosFacturacion,
  emailExiste,
} from "../repository";
import swal from "sweetalert";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const UserInformation = (props) => {
  const navBarRef = React.useRef(NavBar);
  const [Nombre, setNombre] = useState("");
  const Apellidos = useState("");
  const [Email, setEmail] = useState("");
  const [Empresa, setEmpresa] = useState("");
  const [rfc, setRFC] = useState("");
  const [Telefono, setTelefono] = useState("");
  const TelOficina = useState("");
  const [Calle, setCalle] = useState("");
  const [NumExterior, setNumExterior] = useState("");
  const NumInterior = useState("");
  const [Colonia, setColonia] = useState("");
  const [Ciudad, setCiudad] = useState("");
  const [CodigoPostal, setCodigoPostal] = useState("");
  const [Pais, setPais] = useState("");
  const [Estado, setEstado] = useState("");
  const [TipoTarjeta, setTipoTarjeta] = useState("");
  const [NombreTarjeta, setNombreTarjeta] = useState("");
  const [NumeroTarjeta, setNumeroTarjeta] = useState("");
  const [MesTarjeta, setMesTarjeta] = useState("");
  const [AnioTarjeta, setAnioTarjeta] = useState("");
  const [, setStatus] = useState(0);

  //const codigo = props.codigo;

  useEffect(() => {
    buscaInfoUsuario();
  }, []);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    })
  );

  const validaRFCVacio = (texto) => {
    if (texto === "") {
      texto = "XAXX010101000";
    } else {
      texto = rfc;
    }
    return texto;
  };

  const buscaInfoUsuario = () => {
    var usuarioLogin = JSON.parse(sessionStorage.getItem("usuario"));
    if (usuarioLogin !== null) {
      getDatosFacturacion(usuarioLogin[0].idCliente).then((response) => {
        var usuarioMostrar = response.result;
        // console.log(usuarioMostrar);
        if (usuarioMostrar.length > 0) {
          setNumExterior(usuarioMostrar[0].NumExterior);
          setNombre(usuarioMostrar[0].Nombre);
          setEmail(usuarioMostrar[0].Email);
          setRFC(usuarioMostrar[0].RFC);
          setTelefono(usuarioMostrar[0].Telefono);
          setCalle(usuarioMostrar[0].Calle);
          setColonia(usuarioMostrar[0].Colonia);
          setCiudad(usuarioMostrar[0].Ciudad);
          setEstado(usuarioMostrar[0].Estado);
          setCodigoPostal(usuarioMostrar[0].CodigoPostal);
          setPais("");
          setAnioTarjeta("");
          setNombreTarjeta("");
          setNumeroTarjeta("");
          setTipoTarjeta("");
          setMesTarjeta("");
          setEmpresa("");
        }
      });
    }
  };

  const guardaInfoUsuario = (event) => {
    event.preventDefault();
    setStatus(1);
    if (validator.current.allValid() /*&& usuario !== null*/) {
      if (rfc === "") {
        setRFC("XAXX010101000");
      }
      var usuario = JSON.parse(sessionStorage.getItem("usuario"));
      var idClienteWeb = usuario[0].idCliente;
      agregaDatosFacturacion(
        idClienteWeb,
        Nombre,
        "",
        Email,
        Empresa,
        validaRFCVacio(rfc),
        Telefono,
        "",
        Calle,
        NumExterior,
        "",
        Colonia,
        Ciudad,
        CodigoPostal,
        Pais,
        Estado,
        TipoTarjeta,
        NombreTarjeta,
        NumeroTarjeta,
        MesTarjeta,
        AnioTarjeta
      ).then((response) => {
        if (response.status === 200) {
          swal("Aviso", "Información guardada con exito.", "success").then(
            () => {
              window.location = "/Cart";
            }
          );
          buscaInfoUsuario();
        }
      });
    } else {
      validator.current.showMessages();
      swal(
        "Validar información",
        "Favor de validar la información ingresada.",
        "info"
      );
    }
  };

  const validaEmailExiste = (correo) => {
    if (validadorEmail.isEmail(correo)) {
      emailExiste(correo).then((response) => {
        if (sessionStorage.getItem("usuario") === null) {
          if (response === 0) {
            swal(
              "Email no registrado",
              "Por favor registrese para poder continuar.",
              "info"
            ).then(() => {
              window.location = "/Register";
            });
          } else {
            swal(
              "Email registrado",
              "Este correo ya existe. Por favor inicie sesión",
              "info"
            ).then(() => {
              window.location = "/Login";
            });
          }
        }
      });
    }
  };

  return (
    <div>
      {/* NAVBAR */}
  {/*    <NavBar ref={navBarRef} />  */}
      {/* HEADER */}
      <Header
        title="Datos de envío"
        class1="row py-1 bg-warning text-black"
        class2="col-md-6"
        class3="fa fa-shipping-fast"
      />
      <div className="container responsive mb-3">
        <div className="row mt-2">
          <div className="col-3 w-100">
            <div className="row justify-content-end mt-2 ">
              <h6 className="text-align-right">Nombre:</h6>
            </div>
          </div>
          <div className="col-8 mt-0 mb-3 ">
            <input
              name="txtNombre"
              type="text"
              className="form-control"
              autoComplete="off"
              placeholder=""
              maxLength="100"
              defaultValue={Nombre}
              onChange={(e) => {
                setNombre(e.target.value);
              }}
            />
            {validator.current.message("txtNombre", Nombre, "required|string")}
          </div>
        </div>
        <div className="row mt-2 ">
          <div className="col-3">
            <div className="row justify-content-end mt-2  mt-2">
              <h6>Email:</h6>
            </div>
          </div>
          <div className="col-8 mt-0 mb-3">
            <input
              name="txtEmail"
              type="text"
              className="form-control"
              autoComplete="off"
              placeholder=""
              maxLength="100"
              defaultValue={Email}
              onChange={(e) => {
                setEmail(e.target.value);
                validaEmailExiste(e.target.value);
              }}
            />
            {validator.current.message("txtEmail", Email, "required|email")}
          </div>
        </div>
        <div className="row  mt-2 ">
          <div className="col-3">
            <div className="row justify-content-end mt-2  mt-2">
              <h6>RFC:</h6>
            </div>
          </div>
          <div className="col-6 mt-0 mb-3">
            <input
              name="txtRFC"
              type="text"
              defaultValue={rfc}
              className="form-control"
              autoComplete="off"
              placeholder="Capturelo si necesita factura"
              maxLength="18"
              onChange={(e) => {
                setRFC(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="row  mt-2 ">
          <div className="col-3">
            <div className="row justify-content-end mt-2  mt-2">
              <h6>Teléfono:</h6>
            </div>
          </div>
          <div className="col-8 mt-0  mb-3">
            <input
              name="txtTelefono"
              type="text"
              className="form-control"
              autoComplete="off"
              placeholder=""
              defaultValue={Telefono}
              maxLength="20"
              onChange={(e) => {
                setTelefono(e.target.value);
              }}
            />
            {validator.current.message(
              "txtTelefono",
              Telefono,
              "required|string"
            )}
          </div>
        </div>
        <div className="row  mt-2  ">
          <div className="col-3">
            <div className="row justify-content-end mt-2  mt-2">
              <h6>Calle:</h6>
            </div>
          </div>
          <div className="col-8 mt-0  mb-3">
            <input
              name="txtCalle"
              type="text"
              className="form-control"
              autoComplete="off"
              placeholder=""
              maxLength="100"
              defaultValue={Calle}
              onChange={(e) => {
                setCalle(e.target.value);
              }}
            />
            {validator.current.message("txtCalle", Calle, "required|string")}
          </div>
        </div>
        <div className="row mt-2  ">
          <div className="col-3">
            <div className="row justify-content-end mt-2  mt-2">
              <h6>Número:</h6>
            </div>
          </div>
          <div className="col-3 mt-0 mb-3">
            <input
              name="txtNumero"
              type="text"
              className="form-control"
              autoComplete="off"
              placeholder=""
              maxLength="10"
              defaultValue={NumExterior}
              onChange={(e) => {
                setNumExterior(e.target.value);
              }}
            />
            {validator.current.message(
              "txtNumero",
              NumExterior,
              "required|integer"
            )}
          </div>
        </div>
        <div className="row  mt-2  ">
          <div className="col-3">
            <div className="row justify-content-end mt-2  mt-2">
              <h6>Colonia:</h6>
            </div>
          </div>
          <div className="col-8 mt-0  mb-3">
            <input
              name="txtColonia"
              type="text"
              className="form-control"
              autoComplete="off"
              placeholder=""
              maxLength="100"
              defaultValue={Colonia}
              onChange={(e) => {
                setColonia(e.target.value);
              }}
            />
            {validator.current.message(
              "txtColonia",
              Colonia,
              "required|string"
            )}
          </div>
        </div>
        <div className="row  mt-2 ">
          <div className="col-3">
            <div className="row justify-content-end mt-2  mt-2">
              <h6>Ciudad:</h6>
            </div>
          </div>
          <div className="col-8 mt-0  mb-3">
            <input
              name="txtCiudad"
              type="text"
              className="form-control"
              autoComplete="off"
              placeholder=""
              defaultValue={Ciudad}
              maxLength="100"
              onChange={(e) => {
                setCiudad(e.target.value);
              }}
            />
            {validator.current.message("txtCiudad", Ciudad, "required|string")}
          </div>
        </div>
        <div className="row  mt-2  ">
          <div className="col-3">
            <div className="row justify-content-end mt-2  mt-2">
              <h6>Estado:</h6>
            </div>
          </div>
          <div className="col-8 mt-0  mb-3">
            <input
              name="txtEstado"
              type="text"
              className="form-control"
              autoComplete="off"
              placeholder=""
              maxLength="100"
              defaultValue={Estado}
              onChange={(e) => {
                setEstado(e.target.value);
              }}
            />
            {validator.current.message("txtEstado", Estado, "required|string")}
          </div>
        </div>
        <div className="row mt-2  ">
          <div className="col-3">
            <div className="row justify-content-end mt-2 ">
         
              <h6>C.P.:</h6>
            </div>
          </div>
          <div className="col-8 mt-0 mb-3">
            <input
              name="txtCodigoPostal"
              type="text"
              className="form-control"
              defaultValue={CodigoPostal}
              autoComplete="off"
              placeholder=""
              maxLength="10"
              onChange={(e) => {
                setCodigoPostal(e.target.value);
              }}
            />
            {validator.current.message(
              "txtCodigoPostal",
              CodigoPostal,
              "required|integer"
            )}
          </div>
        </div>
        <div className="form">
          <div className="row">
            <div className="col-md-8"></div>
            <div className="col-md">

            <div className="row " >
            <div className="col-md-6 mt-1">
              <button
                type="submit"
                className="btn btn-block btn-warning"
                onClick={(event) => {
                  guardaInfoUsuario(event);
                }}
              >
                <i className="fa fa-save"></i> Guardar
              </button>
            </div>
            <div className="col-md-6 mt-1">
              <button type="submit" className="btn btn-block btn-secondary" onClick={() => { window.location="/Cart";}}>
                <i className="fa fa-window-close"></i> Cancelar
              </button>
            </div>
          </div>
          </div>
          </div>
        </div>
      </div>
      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default UserInformation;
