import React, {Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert";
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";
import OrderItem from "./OrderItem";
import { numberFormat, getPedido } from "../repository";

const  OrderDetails = (props) => {
  const [status, setStatus] = useState(null);
  const [pedido, setPedido] = useState({});
  const [detallePedido, setDetallePedido] = useState({});
  const [source, setSource] = useState("");
  const [items, setItems] = useState(0);
 
  useEffect(() =>
  {
    const getOrder = () => {
      try {
        //params url
        var idPedido = props.match.params.idPedido;
        var source = props.match.params.source;
        setSource(source);              
        getPedido(idPedido)
          .then(response => {         
           // console.log(response);
            if (response.status === "success") {          
              var detallePedido = response.result.recordsets[1];
              setPedido(response.result.recordsets[0]);
              setDetallePedido(response.result.recordsets[1]);
              var items = 0;
              //Calculo los items
              detallePedido.forEach((item) => {
                items += item.Cantidad;
              });                               
              setStatus("success");            
              setItems(items);                                      
            } else {
              Swal(
                "Error obtener la información [Productos]",
                response.message,
                "error"
              );
            }          
          })
          .catch(err =>
            Swal("Error obtener la información [Pedido]", err, "error")
          );        
      } catch (err) {
        Swal("Error obtener la información [Pedido]", err, "error");      
      }              
    };
  
    getOrder();   
  }
  ,[props.match.params.idPedido, props.match.params.source]);

    return (
      <div>
    
        {/* HEADER */}
        <Header
          title={
            status === "success"
              ? "Pedido #" + pedido[0].idPedido
              : "Pedido"
          }
          class1="row py-1 bg-warning text-black"
          class2="col-md-6"
          class3="fa fa-money-check-alt"
        />
        <section id="actions" className="py-3 mb-2">
          <div className="container mt-2  ">
            <div className="row">
              <div className="col-md-3">
                <Link
                  to={source === "1" ? "/home" : "/orders"}
                  className="btn btn-secondary btn-block mb-2"
                >
                  <i className="fas fa-arrow-left"></i> Regresar
                </Link>
              </div>
              {status === "success" && (
                <div className="col-md-9">               
                  <h6>
                    <strong>Fecha: </strong>
                    {pedido[0].Fecha}
                  </h6>

                  <h6>
                    <strong>Guía: </strong>
                    {pedido[0].NumeroGuia}
                  </h6>
                </div>
              )}
            </div>
          </div>
        </section>
        {/* CONTENT */}
        <section id="items">
          <form>
            <div className="container mb-3">
              {status !== "success" && (
                <div className="row">
                  <div className="col-md-3"></div>
                  <div className="col-md-6 text-center">
                    <div className="text-center">
                      <h6>Cargando...</h6>
                      <div
                        className="spinner-border text-warning"
                        role="status"
                        aria-hidden="true"
                      ></div>
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                </div>
              )}
              {status === "success" && (
                <Fragment>
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="list-group">
                        {detallePedido.map((item, index) => (
                          <OrderItem item={item} key={index} />
                        ))}
                      </ul>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12 text-right">
                      <h5>
                        <span className="badge badge-warning ml-1 mr-1">
                          {items}
                        </span>
                        <small>
                          <strong>Productos:</strong>
                        </small>
                        <span className="badge badge-pill badge-warning ml-2 mr-3">
                          ${numberFormat(pedido[0].Total)} 
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                   {/* <div className="col-md-12 mt-3">
                      Consignado:
                      <textarea
                        name="txtConsignado"
                        className="form-control"
                        defaultValue={pedido[0].Consignado}
                        readOnly
                      />
                    </div>
                    */} 
                  </div>
                </Fragment>
              )}
            </div>
          </form>
        </section>
        {/* FOOTER */}
        <Footer />
      </div>
    );
  }


export default OrderDetails;
