import React, { useState, useEffect } from "react";
import Swal from "sweetalert";

import { getProductosByCodeDetalle } from "../repository";

const ProductApplications = (props) => {
  const [productosDetalle, setProductosDetalle] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const codigo = props.codigo;

  useEffect(() => {
    buscaCodigo();  
  }, []);

  const buscaCodigo = () => {
    getProductosByCodeDetalle(codigo)
      .then((response) => {
        if (response.status === "success") {
          setProductosDetalle(response.result);
          setIsLoading(false);
        } else {
          setProductosDetalle([]);
          Swal(
            "Error obtener la información [ProductosDetalle]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) => {
        Swal("Error obtener la información [ProductosDetalle]", err, "error");
      });
  };

  return (
    <div
      className="modal fade"
      id={"modalApps" + codigo}
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby={"modalApps" + codigo + "Label"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title text-center"
              id={"modalApps" + codigo + "Label"}
            >
              <span className="badge badge-warning ml-1 mr-1">
                {productosDetalle.length}
              </span>
              <small>
                {productosDetalle.length > 1 ? "Aplicaciones" : "Aplicación"}
                &nbsp;de:&nbsp;
              </small>
              <span className="badge badge-secondary ml-1 mr-1">{codigo}</span>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              <div className="row bg-warning">
                <div className="col mt-2">
                  <h6>Tipo</h6>
                </div>
                <div className="col mt-2">
                  <h6>Modelo</h6>
                </div>
                <div className="col mt-2">
                  <h6>Condición</h6>
                </div>
              </div>
              {isLoading ? (
                <div className="text-center">
                  <p>Cargando...</p>
                  <div
                    className="spinner-border text-warning"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              ) : (
                productosDetalle.map((item, index) => (
                  <div className="row bg-light border-bottom" key={ Math.random() + codigo +item.Tipo  + item.Modelos + item.Condicion }>
                    <div className="col border-right">
                      <small>{`${index + 1}.- ${item.Tipo}`}</small>
                    </div>
                    <div className="col border-right">
                      <small>{item.Modelos}</small>
                    </div>
                    <div className="col">
                      <small>{item.Condicion}</small>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={props.closeModal}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductApplications;
