import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const NavBarItem = (props) => {    

   useEffect(() => {  
   
  }, []); 
  

      return (
      <li className="nav-item px-2">
        <NavLink
          to={props.item.Ruta}
          className="nav-link"
          activeClassName="nav-link active"
        >
          <h6>
          {props.item.Module}{" "}
          {props.item.Globo === 1 ? (
            <span className="badge badge-success">{props.cartItems}</span>
          ) : (
            ""
          )}
          {props.item.Globo === 2 ? (
            <span className="badge badge-warning">{props.orderItems}</span>
          ) : (
            ""
          )}
          </h6>
        </NavLink>
      </li>

      
    );    
  }
export default NavBarItem;
