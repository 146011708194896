import React, { useState, useEffect, Fragment } from "react";

import { getUbicacionesGrupoNOCEDIS } from "../repository";
import global from "../components/Global";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LocationItem from "../components/LocationItem";

const Locations = () => {
  const [grupos, setGrupos] = useState([]);

  const obtieneGrupos = () => {
    getUbicacionesGrupoNOCEDIS()
      .then((response) => {
        if (response.status === 200) {
          setGrupos(response.data.result);
        } else {
          alert("Error obtener la información");
          setGrupos([]);
        }
      })
      .catch(
        (err) => alert("Error obtener la información " + err),
        setGrupos([])
      );
  };

  useEffect(() => {
    obtieneGrupos();
  }, []);

  return (
    <Fragment>
    <Header/>
      <div className="container mt-3 mb-3">

      
      
          <div className="col-lg-12 text-center">
            <h2 className="section-heading text-uppercase">Ubicaciones</h2>
            <h3 className="section-subheading mb-5 text-muted">
              {global.leyendaUbicaciones}
            </h3>
          </div>

          <div className="accordion" id="accordionLocations">
            {grupos.map((item, index) => (
              <div className="card" key={index}>
                <div className="card-header" id={"heading" + item.OrdenGrupo}>
                  <h2 className="mb-0">
                    <button
                      className="btn btn-block text-left text-secondary"
                      type="button"
                      data-toggle="collapse"
                      data-target={"#collapse" + item.OrdenGrupo}
                      aria-expanded="true"
                      aria-controls={"collapse" + item.OrdenGrupo}
                    >                      
                      {item.Grupo.replace(
                        "ÁREA METROPOLITANA MONTERREY",
                        "ÁREA METROPOLITANA MTY"
                      )}                      
                    </button>
                  </h2>
                </div>

                <div
                  id={"collapse" + item.OrdenGrupo}
                  className="collapse"
                  aria-labelledby={"heading" + item.OrdenGrupo}
                  data-parent="#accordionLocations"
                >
                  <div className="card-body">
                     <LocationItem ordenGrupo={item.OrdenGrupo} />  
                  </div>
                </div>
              </div>
            ))}
          </div>
       
      </div>
      <Footer />
    </Fragment>
  );
};

export default Locations;
