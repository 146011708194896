import React, { useState, useEffect, useRef, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";
import Footer from "../components/Footer";
import { loginNew2 } from "../repository";
import Header from "../components/Header";
import ClientCaptcha from "react-client-captcha";

const Login = () => {
  const [usuario, setUsuario] = useState("");
  const [contrasenia, setContrasenia] = useState("");
  const [rememberMe, setRememberme] = useState(true);
  const [loading, setloading] = useState(0);
  const [, setForceUpdate] = useState(0);
  const [catpchaCorrect, setCaptchaCorrect] = useState(false);
  const [captchaCode, setCaptchaCode] = useState("");
  const [captchaCapturado, setCaptchaCapturado] = useState("");

  const toggleRememberMe = (e) => {
    setRememberme(!rememberMe);
  };

  const submitLogin = (event) => {
    event.preventDefault();
    // console.log("entro alogin");
    setForceUpdate(1);
    setloading(1);
    if (validator.current.allValid() && catpchaCorrect) {
      loginNew2(usuario, contrasenia)
        .then((response) => {
          if (response.statusText === "OK" && response.data !== "") {
            if (rememberMe) {
              localStorage.setItem("usuario", usuario);
              localStorage.setItem("contrasenia", contrasenia);
              localStorage.setItem("rememberMe", rememberMe);
            } else {
              localStorage.setItem("usuario", "");
              localStorage.setItem("contrasenia", "");
              localStorage.setItem("rememberMe", rememberMe);
            }
            window.location = "/Home";
          } else {
            Swal(
              "Error al Ingresar al Sistema",
              "Usuario y/o contraseña invalido(s).",
              "error"
            );
          }
          //setloading(0);
        })
        .catch((err) => {
          Swal("Error", "Error de Conexón al Sistema", "error");
          // setloading(0);
        });
      setloading(0);
    } else {
      validator.current.showMessages();
      Swal("Aviso", "Favor de validar la información ingresada.", "info");
      setloading(0);
    }
  };

  useEffect(() => {
    validator.current.hideMessages();
    var valor = localStorage.getItem("rememberMe");
    if (valor === "true") {
      setRememberme(true);
      setUsuario(localStorage.getItem("usuario"));
      setContrasenia(localStorage.getItem("contrasenia"));
    }
  }, []);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    })
  );

  return (
    <div>
      <Header
        title="Iniciar Sesión"
        class1="row py-1 bg-warning text-black"
        class2="col-md-6"
        class3="fa fa-sign-in"
      />

      {/* LOGIN */}
      <section id="login">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto mt-5">
              <div className="card">
              <div className="card-header text-center" >
              <h3> Iniciar sesión
              </h3>
 
              </div>
                <div className="card-body">
                  <form
                    onSubmit={(e) => {
                      submitLogin(e);
                    }}
                  >
                    <div className="form-group">
                      <label htmlFor="usuario">Usuario</label>
                      <input
                        type="text"
                        name="usuario"
                        className="form-control"
                        onChange={(e) => setUsuario(e.target.value)}
                        defaultValue={usuario}
                        maxLength="20"
                        autoComplete="off"
                      />
                      {validator.current.message(
                        "usuario",
                        usuario,
                        "required|string"
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Contraseña</label>
                      <input
                        type="password"
                        name="contrasenia"
                        className="form-control"
                        onChange={(e) => setContrasenia(e.target.value)}
                        defaultValue={contrasenia}
                        maxLength="20"
                      />
                      {validator.current.message(
                        "contrasenia",
                        contrasenia,
                        "required|string"
                      )}
                    </div>

                    <div className="form-group">
                      <ClientCaptcha
                        captchaCode={(code) => {
                          setCaptchaCode(code);
                          setCaptchaCorrect(false);
                        }}
                      />
                      Capture el código mostrado.
                      <input
                        type="text"
                        name="captcha"
                        className={
                          catpchaCorrect
                            ? "form-control mt-3 text-success"
                            : "form-control mt-3 text-danger"
                        }
                        onChange={(e) => {
                          setCaptchaCapturado(e.target.value);
                          if (e.target.value === captchaCode) {
                            setCaptchaCorrect(true);
                          } else {
                            setCaptchaCorrect(false);
                          }
                        }}
                        defaultValue={captchaCapturado}
                        maxLength="50"
                      />
                      {validator.current.message(
                        "captcha",
                        captchaCapturado,
                        "required|string"
                      )}
                    </div>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        name="chkRemember"
                        id="chkRemember"
                        onChange={(e) => toggleRememberMe(e)}
                        checked={rememberMe}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="chkRemember"
                      >
                        Recordar Usuario y Contraseña
                      </label>
                    </div>
                    {loading === 0 && (
                      <Fragment>
                        <div className="row">
                          <div className="col-md">
                            <button
                              value="Aceptar"
                              className="btn btn-warning btn-block mt-3"
                              onClick={(e) => {
                                submitLogin(e);
                              }}
                            >
                              Aceptar
                            </button>
                          </div>
                          <div className="col-md">
                            <button
                              className="btn btn-secondary btn-block mt-3"
                              onClick={(e) => {
                                e.preventDefault();
                                window.location = "/search";
                              }}
                            >
                              Cancelar
                            </button>
                          </div>
                        </div>
                      </Fragment>
                    )}
                    {loading === 1 && (
                      <div className="text-center mt-3">
                        <h6>Cargando...</h6>
                        <div
                          className="spinner-border text-warning"
                          role="status"
                          aria-hidden="true"
                        ></div>
                      </div>
                    )}

                    <div className="text-center mt-3">
                      <h6>
                        <button
                          className="btn btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location = "Forgot";
                          }}
                        >
                          ¿Olvidó sus datos de acceso?{" "}
                        </button>
                      </h6>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default Login;
