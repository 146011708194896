import React, { Fragment, useState, useEffect } from "react";
import SearchByMaker from "./SearchByMaker";
import SearchByDesc from "./SearchByDesc";
import SearchByVehicle from "./SearchByVehicle";
import SearchByCode from "./SearchByCode";

const MenuCatalogo = ({ actualizarproductos, opcionNav }) => {
  //const [tiporecibo, setTipoRecibo] = useState(opcionNav);
  // const [estadoActivo, setEstadoActivo] =  useState("show active");
   const [ligaHash, setLigaHash] = useState(window.location);


  const TabActivo = (opcion) => {
  
    if (opcion == opcionNav) {
      return " nav-item  nav-link active";
    } 
    else
    {
      return " nav-item  nav-link";
    }
  };

  const activarTabClick = (opcion) => {
  

    if (opcion == opcionNav) 
      {
      return "tab-pane fade active show";
    } 
    else 
    {
      return "tab-pane fade";
    }
  
  };
  useEffect(() => {
   // setLigaHash(window.location.hash);
  }, [opcionNav]);

  return (
  <Fragment>

      <nav>
     
        <div className="nav nav-tabs bg-light mt-3 mb-3" id="nav-tab-2" role="tablist">
          <a
            className={TabActivo(1)}
            id="nav-14-tab"
            data-toggle="tab"
            href="#nav-descripcion"
            role="tab"
            aria-controls="nav-descripcion"
            aria-selected="false"
          >
            Descripción
            {/*  {isMobileOnly === true ? "Desc." : "Descripción"}  */}
          </a>
          <a
            className={TabActivo(2)}
            id="nav-12-tab"
            data-toggle="tab"
            href="#nav-marca"
            role="tab"
            aria-controls="nav-marca"
            aria-selected="false"
          >
            Marca
          </a>

          <a
            className={TabActivo(3)}
            id="nav-11-tab"
            data-toggle="tab"
            href="#nav-vehiculo"
            role="tab"
            aria-controls="nav-vehiculo"
            aria-selected="true"
          >
            Vehículo
          </a>

          <a
            className={TabActivo(4)}
            id="nav-13-tab"
            data-toggle="tab"
            href="#nav-codigo"
            role="tab"
            aria-controls="nav-3"
            aria-selected="false"
          >
            Código
          </a>
        </div>
        </nav>

      <div className="tab-content" id="nav-tabContent2">
        <div
          className={activarTabClick(1)}
          id="nav-descripcion"
          role="tabpanel"
          aria-labelledby="nav-14-tab"
        >
          <SearchByDesc actualizarproductos={actualizarproductos} />
        </div>
        <div
           className={ activarTabClick(2)}
          id="nav-marca"
          role="tabpanel"
          aria-labelledby="nav-12-tab"
        >
          <SearchByMaker actualizarproductos={actualizarproductos} />
        </div>

        <div
          className={   activarTabClick(3)}
          id="nav-vehiculo"
          role="tabpanel"
          aria-labelledby="nav-11-tab"
        >
          <SearchByVehicle actualizarproductos={actualizarproductos} />
        </div>

        <div
          className={   activarTabClick(4)}
          id="nav-codigo"
          role="tabpanel"
          aria-labelledby="nav-13-tab"
        >
          <SearchByCode actualizarproductos={actualizarproductos} />
        </div>
      </div>
</Fragment>
  
  );
};

export default MenuCatalogo;
