import { Fragment } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const OurMark = () => {
  return (
    <Fragment>
      <Header />

      <div className="container mt-3 mb-3">
        <div className="row">
          <div className="col-lg-12 text-center"></div>
        </div>
        <div className="row-flex">
          <div className="col-lg">
            <h2 className="section-heading  text-center">
             Nuestra Marca
            </h2>

            <h3 className="section-subheading text-muted"></h3>
            <div className="timeline-panel">
              <div className="timeline-body">
                <p className="text-muted text-justify">
               <br /> Nuestro Director General se fijó una meta hace 40 años, integrar y desarrollar componentes para los sistemas automotrices de dirección, suspensión, amortiguación y tracción en una estrategia enfocada a satisfacer las necesidades de los instaladores y mecánicos que buscan soluciones con una sola marca-proveedor. Así fue concebida y así nació nuestra marca GROB.
                <br /><br /> Los productos GROB cumplen con los más altos estándares de calidad, garantizando el mejor desempeño en rendimiento y durabilidad equivalentes a los componentes instalados de fábrica en los vehículos. Diseñados para adaptarse perfectamente a las especificaciones técnicas del mercado y las condiciones de manejo en México.

                </p>
              
                <p className="text-muted  text-justify">
                <label className="text-muted text-left"> <b> Cobertura Extensiva: </b></label> 
                  <br />  GROB se enfoca en brindar la mayor cobertura posible del parque vehicular mexicano, asegurando que sus componentes estén disponibles para una amplia variedad de marcas y modelos desde automóviles subcompactos hasta vehículos utilitarios o deportivos. GROB ofrece soluciones para cada segmento.
                </p>

                <p className="text-muted  text-justify">
                <label className="text-muted text-left"> <b>Integración de Sistemas: </b></label> 
                  <br />  Al consolidar componentes de dirección, suspensión, tracción y amortiguación, GROB simplifica la experiencia del cliente, ofreciendo todo lo necesario para mantener en óptimas condiciones los vehículos que circulan en el país.
                </p>
                <p className="text-muted  text-justify">
                <label className="text-muted text-left"> <b>Enfoque en el Aftermarket:</b></label> 
                  <br /> Diseñada específicamente para atender las necesidades del mercado de repuesto, GROB proporciona productos que combinan precio competitivo con desempeño superior. Su enfoque en el Aftermarket garantiza disponibilidad, soporte técnico y facilidad de instalación.
                </p>
                <p className="text-muted  text-justify">
                <label className="text-muted text-left"> <b> Ventajas Competitivas de GROB: </b></label> 
                  <br />Confianza Garantizada, respaldo técnico y garantías extensas que aseguran tranquilidad de los consumidores e instaladores.
                </p>
                <p className="text-muted  text-justify">
                <label className="text-muted text-left"> <b> Innovación y Tecnología: </b></label> 
                  <br />Uso de materiales avanzados y procesos de fabricación de vanguardia para ofrecer productos de alta fiabilidad.
                </p>
                <p className="text-muted  text-justify">
                <label className="text-muted text-left"> <b> Red de Distribución: </b></label> 
                  <br />Acceso amplio a través de una red de distribuidores autorizados, con excelente disponibilidad en todo el territorio mexicano.


                </p>
              </div>
            </div>
          </div>
          <div className="col">
           
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default OurMark;
