import React, {  useState, useRef , useEffect} from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";
import ReactSearchBox from "react-search-box";
import {
  getAllVehiculos,
  getModelos,
  getFamilias,
  getProductos,
} from "../repository";
//import ProductItem from "../components/ProductItem";

const SearchByVehicle = ({actualizarproductos}) => {

    const [, setStatus] = useState(null);
    const [idVehiculo, setIdVehiculo] = useState(0);
    const [idModelo, setIdModelo] = useState(0);
    const [idFamilia, setIdFamilia] = useState(-1);
    const [vehiculos, setVehiculos] = useState([]);
    const [modelos, setModelos] = useState([]);
    const [familias, setFamilias] = useState([]);
    const [, setProductos] = useState([]);   
    const [loading, setLoading ] = useState(0);
    const [vehiculoSeleccionado, setVehiculoSeleccionado] = useState("");

    const validator = useRef(
      new SimpleReactValidator({
        element: (message) => (
          <span className="text-danger">
            <i className="fa fa-exclamation-circle" aria-hidden="true" />
            {` ${message}`}
          </span>
        ),
        messages: {
          default: "Campo Requerido",    
        },
      })
    );

  useEffect(() => {
      obtieneVehiculos();  
      validator.current.hideMessages();
    }, []);

  const obtieneVehiculos = () => {
    getAllVehiculos()
      .then((response) => {      
        if (response.status === "success") {                   
          setVehiculos(response.result);    
        //  console.log(response.result);
          
        } else {
          Swal(
            "Error obtener la información [Vehiculos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Vehiculos]", err, "error")
      );
  };

  const handleSearchBoxChange = () => {   
    setIdModelo(0);
    setModelos([]);
    setIdFamilia(-1);
    setFamilias([]);
    setProductos([]);
    setStatus(null);   
    actualizarproductos([]);  
};
  
  const handleSearchBoxSelect = (record) => {     
    setIdVehiculo(record.item.key);
    setVehiculoSeleccionado(record.item.value);
    //console.log(record.item);
    setIdModelo(0);
    setModelos([]);
    setIdFamilia(-1);
    setFamilias([]);
    setProductos([]);
    setStatus(null);   
    obtieneModelos(record.item.key);
    obtieneFamilias(0, 0);
    actualizarproductos([]);  
  };

  const handleComboChange = (event) => { 
    actualizarproductos([]);  
    if (event.target.name === "idModelo") {     
      setIdFamilia(-1);
      setFamilias([]);
      setProductos([]);
      setStatus(null);
      setIdModelo(event.target.value);
      obtieneFamilias(idVehiculo, event.target.value);      
    }
    if (event.target.name === "idFamilia") {
      setProductos([]);
      setIdFamilia(event.target.value);
      setStatus(null);     
    }
  };

  const obtieneModelos = (idVehiculo) => {
    getModelos(idVehiculo)
      .then((response) => {
        if (response.status === "success") {
          var items = response.result.map((item) => {
            return { value: item.Modelo, text: item.Modelo };
          });
            setModelos([{ value: 0, text: items.length > 0 ? "[Seleccione]" : "[---]" },].concat(items));                  
        } else {
          Swal(
            "Error obtener la información [Modelos]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Modelos]", err, "error")
      );
  };

  const obtieneFamilias = (idVehiculo, idModelo) => {  
    getFamilias(idVehiculo, idModelo)
      .then((response) => {
        if (response.status === "success") {
          var items = {};
          if (idVehiculo > 0 && idModelo > 0)
            items = response.result.map((item) => { return { value: item.idFamilia, text: item.Familia }; });            
            setFamilias([ { value: -1, text: items.length > 0 ? "[Seleccione]" : "[---]" }, ].concat(items));        
        } else {
          Swal(
            "Error obtener la información [Familias]",
            response.message,
            "error"
          );
        }
      })
      .catch((err) =>
        Swal("Error obtener la información [Familias]", err, "error")
      );
  };

  //Search Products
  const search = (e) => {
    e.preventDefault();
    setLoading(1);
    setProductos([]);
    setStatus(null);    
    if (validator.current.allValid()) {   
      getProductos(idVehiculo,idFamilia,idModelo)
        .then((response) => {         
          if (response.status === "success") {
            setStatus("success");
            setProductos(response.result);
            actualizarproductos(response.result);      
            setLoading(0);           
          } else {
            setLoading(0);           
            Swal(
              "Error obtener la información [Productos]",
              response.message,
              "error"
            );
          }
        })
        .catch((err) => {
          setLoading(0);     
          Swal("Error obtener la información [Productos]", err, "error");
        });
    } else {    
      validator.current.showMessages();
      setLoading(0);   
    }
  };

  return (
      <div>
        {/* CONTENT */}
        <section id="searchContent" className="mt-3">
          <form onSubmit={search}>
            <div className="container">
              <div className="row">
                <div className="col-md-3 mb-3 form-group">
                  <h6>Vehículo:</h6>
                  <ReactSearchBox
                    className="form-control"
                    name="idVehiculo"
                    key="idVehiculo"
                  
                    placeholder= {vehiculoSeleccionado ? vehiculoSeleccionado: "Capture su Vehiculo"}
                    value= ""
                    data={vehiculos}
                 
                    onSelect={handleSearchBoxSelect}                    
                    onChange={handleSearchBoxChange}
                    inputBoxFontSize="16"
                  />
                  {validator.current.message(
                    "idVehiculo",
                    idVehiculo,
                    "required|alpha_num"
                  )}
                </div>
                <div className="col-md-3 mb-3 form-group">
                  <h6>Modelo:</h6>
                  <select
                    name="idModelo"
                    key="idModelo"
                    className="form-control"
                    onChange={handleComboChange}
                  >
                    {modelos.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                  {validator.current.message(
                    "idModelo",
                    idModelo,
                    "required|numeric|min:1,num"
                  )}
                </div>
                <div className="col-md-3 form-group">
                  <h6>Familia:</h6>
                  <select
                    name="idFamilia"
                    key="idFamilia"
                    className="form-control"
                    onChange={handleComboChange}
                  >
                    {familias.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    ))}
                  </select>
                  {validator.current.message(
                    "idFamilia",
                    idFamilia,
                    "required|numeric|min:0,num"
                  )}
                </div>
                <div className="col-md-3 form-group">
                  <h6>&nbsp;</h6>
                  <button className="btn btn-secondary btn-block" type="submit">
                    <i className="fa fa-search mr-1"></i>Buscar
                  </button>
                </div>
              </div>

                    
              {loading === 1 && (
                <div className="text-center">
                  <h6>Cargando...</h6>
                  <div
                    className="spinner-border text-warning"
                    role="status"
                    aria-hidden="true"
                  ></div>
                </div>
              )}
              {/*
              {status === "success" && (
                <Fragment>
                  <div className="row mt-3">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 text-center">
                      <h6>
                        <span className="badge badge-warning ml-1 mr-1">
                          {productos.length}
                        </span>
                        <small>
                          <strong>Producto(s) encontrado(s)</strong>
                        </small>
                      </h6>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                  <div className="row">
                    {productos.map((item, index) => (
                      <ProductItem                       
                        item={item}
                        index={index}
                        add={props.addToCart}
                        key={index}
                      />
                    ))}
                  </div>
                </Fragment>
              )}
              */}

            </div>
          </form>
        </section>
      </div>
    );
  };

export default SearchByVehicle;
