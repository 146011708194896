import React from "react";
import "./css/App.css";

/*Prime React Components*/
import 'primereact/resources/themes/rhea/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../node_modules/@syncfusion/ej2-base/styles/material.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';


import Router from "./Router";




function App() {
  return (
    <div className="App">
      <Router/>
    </div>
  );
}

export default App;
