import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./screens/Login";
import Home from "./screens/Home";
//import Search from "./screens/Search";
//import SearchWithCode from "./screens/SearchWithCode";
import Cart from "./screens/Cart";
import OrderDetails from "./components/OrderDetails";
import OrderDetailsAdmon from "./components/OrderDetailsAdmon";
import Orders from "./screens/Orders";
import Stock from "./screens/Stock";
import NotFound from "./screens/NotFound";
//import { isAuthenticated } from "./repository";
import Forgot from "./screens/Forgot";
import Register from "./screens/Register";
import UserInformation from "./components/UserInformation";
import Admon from "./screens/Admon";
import MainPage from "./screens/MainPage";
import Locations from "./screens/Locations";
import About from "./screens/About";
import Products from "./screens/Products";
import Contact from "./screens/Contact";
import OurMark from "./screens/OurMark";
import GROBMechanics from "./screens/GROBMechanics";
import ExpertsTips from "./screens/ExpertsTips";
import GOVIAwards from "./screens/GOVIAwards";
import JobBoard from "./screens/JobBoard";
import BuyAndPickUp from "./screens/BuyAndPickUp";
import CustomerServices from "./screens/CustomerServices";
import StoreExpansion from "./screens/StoreExpansion";

class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* Configuración de Rutas y Páginas */}
        <Switch>
          {/*    <Route exact path="/" component={Search} />      */}
          <Route exact path="/" component={MainPage} />

          <PrivateRoute exact path="/home">
            <Home />
          </PrivateRoute>
          <PrivateRoute exact path ='/ourmark'>
            <OurMark/>
            </PrivateRoute>


            <PrivateRoute exact path ='/Buy'>
              <BuyAndPickUp></BuyAndPickUp>
            </PrivateRoute>
            <PrivateRoute exact path= '/CustomerServices'>
              <CustomerServices></CustomerServices>
            </PrivateRoute>
            <PrivateRoute exact path = '/StoreExpansion'>
              <StoreExpansion></StoreExpansion>
            </PrivateRoute>
        
          <PrivateRoute exact path='/GROBmechanics'>
            <GROBMechanics></GROBMechanics>
          </PrivateRoute>
          <PrivateRoute exact path='/ExpertsTips'>
            <ExpertsTips></ExpertsTips>
          </PrivateRoute>
          <PrivateRoute exact path='/GOVIAwards'>
            <GOVIAwards></GOVIAwards>
          </PrivateRoute>
          <PrivateRoute exact path ='/JobBoard'>
            <JobBoard></JobBoard>
          </PrivateRoute>
          <PrivateRoute exact path ="/products">
            <Products></Products>
          </PrivateRoute>
          <PrivateRoute  exact path="/about" >
            <About></About>
          </PrivateRoute>
          <PrivateRoute exact path="/Admon">
            <Admon />
          </PrivateRoute>
          <PrivateRoute exact path="/Login">
            <Login />
          </PrivateRoute>
          <PrivateRoute exact path="/Search">
            <MainPage></MainPage>
          </PrivateRoute>
          <PrivateRoute exact path="/Register">
            <Register />
          </PrivateRoute>
          <PrivateRoute exact path="/Forgot">
            <Forgot />
          </PrivateRoute>
          <PrivateRoute exact path="/Main">
            <MainPage />
          </PrivateRoute>
          <PrivateRoute exact path="/Locations">
            <Locations />
          </PrivateRoute>
          <PrivateRoute exact path="/Shipping">
            <UserInformation />
          </PrivateRoute>
          <PrivateRoute exact path="/cart">
            <Cart />
          </PrivateRoute>
          <PrivateRoute exact path="/Contact">
            <Contact/>
          </PrivateRoute>

          <Route
            exact
            path="/search/:codigo"
            render={(props) => <SearchWithCode {...props} />}
          />
          <Route
            exact
            path="/orderDetails/:idPedido/:source"
            render={(props) => <OrderDetails {...props} />}
          />
          <Route
            exact
            path="/orderDetailsAdmon/:idPedido/:source"
            render={(props) => <OrderDetailsAdmon {...props} />}
          />
          <PrivateRoute exact path="/orders">
            <Orders />
          </PrivateRoute>
          <PrivateRoute exact path="/stock">
            <Stock />
          </PrivateRoute>
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...props }) {
  //console.log("auth: " + auth);

  return <Route {...props} render={({ location }) => children} />;
}

export default Router;
