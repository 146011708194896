import React,{ useEffect } from "react";
import { JavascriptViewer } from "@3dweb/360javascriptviewer";
import Global from "./Global";

const Product360Viewer = (props) => {

    const item = props.item;

  useEffect(() => {
    const jsv = new JavascriptViewer({
      mainHolderId: "jsv-holder",
      mainImageId: "jsv-image",
      totalFrames: 19,
      imageUrlFormat: "img_0_0_x.jpg",
      speed: 80,
      defaultProgressBar: true,
      inertia: 20,
      reverse: true,
      autoRotate: 2,
      autoRotateSpeed: -150,
    });
    jsv.start();  
     
    console.log(item);
   
  }, []);

  return (
    <div
      className="modal fade"
      id={"modal360" + item.Codigo + props.key}
      data-backdrop="static"
      data-keyboard="false"
      tabIndex="-1"
      aria-labelledby={
        "modal360" + item.Codigo + props.key + "Label"
      }
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title text-center"
              id={"modal360" + item.Codigo + props.key + "Label"}
            >
              {item.Familia} {item.Codigo} (360)
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={props.closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div id="jsv-holder">
              <img
                id="jsv-image"
                alt=""
                src={
                  props.catalogo
                    ? Global.urlFotos360 +
                      props.item.Codigo.toUpperCase() +
                      "/images/img_0_0_0.jpg"
                    : Global.urlFotos360 +
                      props.item.RutaImages +
                      "/img_0_0_0.jpg"
                }
                style={{
                  maxWidth: 100 + "%",
                  height: 200 + "px",
                
                  objectFit: "contain",                 
                }}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={props.closeModal}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product360Viewer;
