import { Fragment } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const About = () => {
  return (
    <Fragment>
      <Header />

      <div className="container mt-3 mb-3 ">
        <div className="row">
          <div className="col-lg-12 text-center"></div>
        </div>
        <div className="row-flex">
          <div className="col-lg">
            <h2 className="text-center">
            GOVI, un renacer en Monterrey
            </h2>


            <h3 className="text-muted"></h3>
            <div className="timeline-panel">
              
              <div className="timeline-body">

          
                <p className="text-muted text-justify">
                  En 1980 Grupo GOVI fué fundada por mi padre el Sr. Don Roberto
                  González Villareal.
                  <br />
                  <br />
                  Posteriormente decidí enfocar todo el esfuerzo del negocio en
                  la comercialización de partes para la Suspensión Automotriz
                  con el sueño y la necesidad de que los clientes encontraran en
                  una sola parte todos los productos; al haber cumplido este
                  sueño, logramos ser la primer empresa especializada en el
                  Noreste del país dedicada exclusivamente a la venta de partes
                  de Suspensión.
                  <br />
                  <br />
                  Contamos con un equipo de profesionales responsables y leales,
                  quienes tienen la consigna de atender y asesorar a Nuestros
                  Clientes de manera rápida y sin errores.
                  <br />
                  <br />
                  Juntos crecemos cada día sirviendo a favor de las necesidades
                  de surtido, calidad y rentabilidad, obligándonos a ser cada
                  vez mejores.
                  <br />
                  <br />
                  Grupo GOVI es dueño de la marca <b>GROB</b> Suspensión
                  Automotriz y <b>GROB COMFORT</b> Amortiguadores, que nacieron
                  con el objetivo de ofrecer todos los productos en una sola
                  calidad y en un solo envase, cubriendo los estándares de
                  calidad-precio.
                  <br />
                  Nuestros clientes nos dan retroalimentación de las necesidades
                  de calidad y éstas son atendidas y resueltas de inmediato por
                  nuestras fábricas. Esto le puede dar al cliente la seguridad
                  que en todo momento va a encontrar en nuestra marca CALIDAD.
                  <br />
                  <br />
                  Ahora con la nueva sangre del negocio, <b>
                    Antonio González
                  </b>{" "}
                  y sus colaboradores, cada año hacen una revisión de los
                  vehículos ingresados al mercado y de las necesidades de los
                  mismos, para actualizar los nuevos productos sin descuidar los
                  ya existentes, esto garantiza siempre una constante y
                  actualizada EXISTENCIA.
                  <br />
                  Sostenemos constantes juntas con los diferentes departamentos
                  para analizar y encontrar procesos fáciles, rápidos y seguros
                  con la finalidad de ofrecer a nuestros clientes un EXCELENTE
                  SERVICIO.
                </p>
                <br />
                <p className="text-center">
                  <strong>Edgar González Lozano</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="col">
            <img
                  src={window.location.origin + "/banner/img_centro_tienda.png"}
                  alt=""
                  style={{ width: 100+ "%", height: 100 + "%" }}
                />
            </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default About;
