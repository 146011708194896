import React, { useState, useEffect, Fragment } from "react";

import ProductItems from "../components/ProductItems";
import { getFamiliasImg } from "../repository";

import Header from "../components/Header";
import Footer from "../components/Footer";
//import global from "../global";

const Products = () => {
  const [familias, setFamilias] = useState([]);

  const obtieneFamiliasInicio = () => {
    getFamiliasImg()
      .then((response) => {
        if (response.status === 200) {
          setFamilias(response.data.result);
          //console.log(window.location.hash);
        //  console.log(response.data.result);
        } else {
          alert("Error obtener la información del catalogo de  [Ubicaciones]");
          setFamilias([]);
        }
      })
      .catch(
        (err) => alert("Error obtener la información [Ubicaciones] " + err),
        setFamilias([])
      );
  };

  const asginaActiveTab = (item) => {
    if (window.location.hash == "") {
      if(item==1)
        {
          return "nav-item nav-link active";
        }
        else
        {
          return "nav-item nav-link"
        }
    } else {
      if (item == 1 && window.location.hash == "#Nav-1") {
        return "nav-item nav-link  active";
      } else if (item == 2 && window.location.hash == "#Nav-2") {
        return "nav-item nav-link  active";
      } else if (item == 3 && window.location.hash == "#Nav-3") {
        return "nav-item nav-link  active";
      } else if (item == 4 && window.location.hash == "#Nav-4") {
        return "nav-item nav-link  active";
      } else {
        return "nav-item nav-link";
      }
    }
  };


  const MuestraPrimero = (item) => {

    console.log(item);
    if (window.location.hash == "") {
      if(item==1)
        {
          return "tab-pane fade show active";
        }
        else
        {
          return "tab-pane fade"
        }
    } else {
      if (item == 1 && window.location.hash == "#Nav-1") {
        return "tab-pane fade show active";
      } else if (item == 2 && window.location.hash == "#Nav-2") {
        return "tab-pane fade show active";
      } else if (item == 3 && window.location.hash == "#Nav-3") {
        return "tab-pane fade show active";
      } else if (item == 4 && window.location.hash == "#Nav-4") {
        return "tab-pane fade show active";
      } else 
      {
        return "tab-pane fade";
      }
    }
  };

  useEffect(() => {
    obtieneFamiliasInicio();
  }, []);

  return (
    <Fragment>
      <Header />

      <div className="container mt-3 mb-3">
        <div className="col-lg-12 text-center" name="divcol">
          <h2 className="section-heading text-uppercase">Productos</h2>
        </div>
        <div className="row mt-3">
          <div className="col-md-12">
            <nav>
              <div
                className="nav nav-tabs bg-light"
                id="nav-tab"
                name="navtab"
                role="tablist"
              >
                <Fragment>
                  {familias.map((item) => (
                    <a
                      className={
                        asginaActiveTab(item.idFamilia)

                        /*  item
                              ? item.idFamilia < 2
                                ? "nav-item nav-link active"
                                : "nav-item nav-link"
                              : "nav-item nav-link"
                              */
                      }
                      id={item.strDescripcion + "tab"}
                      data-toggle="tab"
                      href={"#nav-" + item.idFamilia}
                      role="tab"
                      key={item.strDescripcion}
                      name={item.strDescripcion}
                      aria-controls={"nav-" + item.idFamilia}
                      aria-selected={
                        item ? (item.idFamilia < 2 ? "true" : "false") : "false"
                      }
                    >
                      {item.strDescripcion}
                    </a>
                  ))}
                </Fragment>
              </div>
            </nav>
            <div className="tab-content" name="tabcontent" id="nav-tabContent">
              {familias.map((item, index) => (
                <div
                  className={ MuestraPrimero(item.idFamilia)
                   /* item
                      ? item.idFamilia < 2
                        ? "tab-pane fade show active"
                        : "tab-pane fade"
                      : "tab-pane fade"
                      */
                  }
                  id={"nav-" + item.idFamilia}
                  role="tabpanel"
                  name="tabpanel"
                  key={item.strDescripcion}
                  aria-labelledby={"nav-" + item.idFamilia + "-tab"}
                >
                  <ProductItems key={index} idFamilia={item.idFamilia} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default Products;
